import { Badge } from '@kidsmanager/ui-core';
import { nameToColor } from '@kidsmanager/util-common';

export type DayValueType = 'planned' | 'logged' | 'other';

export interface MonthDayValueProps {
  label: string | number | null;
  type: DayValueType;
  color?: string;
}

export const MonthDayValue = (props: MonthDayValueProps) => {
  const formatLabel = (label: string | number | null) => {
    if (typeof label === 'number') {
      return Math.round(label * 10) / 10;
    } else if (typeof label === 'string') {
      return label;
    }
    return '';
  };

  switch (props.type) {
    case 'planned': {
      const label = props.label?.toString() || '';
      const color = nameToColor(label);
      return <Badge name={label} symbol={label} color={color} />;
    }
    case 'logged':
      return <span>{formatLabel(props.label)}</span>;
    default:
      return <span>{formatLabel(props.label)}</span>;
  }
};
