export {}; // Ensure this is treated as a module to allow us to define global augmentation

declare global {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Array<T> {
    takeAndSum: (this: Array<number>, take: number) => number;

    findLastIndex: (
      predicate: (value: T, index: number, array: T[]) => unknown
    ) => number;
  }
}

Array.prototype.takeAndSum = function (this: Array<number>, take: number) {
  return this.slice(0, take).reduce((acc, p) => acc + p, 0);
};

Array.prototype.findLastIndex = function (predicate) {
  for (let i = this.length - 1; i >= 0; --i) {
    if (predicate(this[i], i, this)) {
      return i;
    }
  }
  return -1;
};
