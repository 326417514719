import { IAssignment, IShift, IShiftSpec } from '@kidsmanager/util-models';

export const compileShift = (
  planned: IAssignment | undefined,
  shifts: IShift[],
  specs: IShiftSpec[]
): IShift => {
  if (!planned || !planned.shifts.length) {
    return shifts[0];
  }

  const spec = specs.find((spec) => spec.id === planned.shifts[0]);
  const closest = shifts.find((shift) => shift.name === spec?.name);

  if (!closest || !spec) {
    return shifts[0];
  }

  const shift = { ...closest };

  const [hours, mins] = spec.start.split(':').map(Number);
  shift.nominalStartHours = hours + mins / 60;
  shift.nominalLengthHours = spec.hrs;

  return shift;
};
