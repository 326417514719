import { useEffect, useState } from 'react';

export interface ExpansionPanelProps {
  id: string;
  label: string;
  children?: React.ReactNode;
  expanded?: boolean;
  meta?: string;
  onToggle?: (id: string) => void;
}

export const ExpansionPanel = (props: ExpansionPanelProps) => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setExpanded(!!props.expanded);
  }, [props.expanded]);

  const onToggleExpand = () => {
    if (props.onToggle) {
      props.onToggle(props.id);
    } else {
      setExpanded((prev) => !prev);
    }
  };

  return (
    <div
      className="outline-focus rounded bg-white"
      tabIndex={0}
      onKeyUp={(e) => e.key === 'Enter' && onToggleExpand()}
    >
      <div
        onClick={onToggleExpand.bind(this)}
        className="relative grid cursor-pointer grid-cols-[auto_24px] p-2"
      >
        <span>{props.label}</span>
        {props.meta && (
          <span className="absolute right-10 top-2.5 text-sm text-black/30">
            {props.meta}
          </span>
        )}
        <span
          className={`material-icons ${expanded ? 'rotate-180' : ''} transition-transform duration-300 ease-in`}
        >
          keyboard_arrow_down
        </span>
      </div>
      <div
        className="grid transition-[grid-template-rows] duration-300 ease-in-out"
        style={{ gridTemplateRows: expanded ? '1fr' : '0fr' }}
      >
        <div className="overflow-hidden">
          <div className="p-2">{props.children}</div>
        </div>
      </div>
    </div>
  );
};
