import { IEntry } from '@kidsmanager/util-models';
import {
  formatDuration,
  formatEnd,
  formatStart
} from '../../timesheet-common/timesheet-formatters';
import {
  IconButton,
  Input,
  Revealer,
  Select,
  Textarea,
  Option,
  GhostButton,
  Button
} from '@kidsmanager/ui-core';
import { useEffect, useState } from 'react';
import { createPeriod24 } from '@kidsmanager/util-common';
import { EntryOptions } from '../helpers/work-nonwork-extractor';

export interface IEditableEntry extends IEntry {
  state?: 'readonly' | 'new' | 'dirty';
}

export interface EditableEntryProps {
  entry: IEditableEntry;
  options: EntryOptions | undefined;
  delete?: (entry: IEntry) => void;
  save?: (source: IEntry, updated: IEditableEntry) => void;
}

const hasLabel = (options: EntryOptions | undefined, label: string) => {
  if (!options) {
    return false;
  }
  return options.work.findIndex((x) => x === label) >= 0;
};

export const EditableEntry = (props: EditableEntryProps) => {
  const { options, entry } = props;
  const [editing, setEditing] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [hasSaveAsOption, setHasSaveAsOption] = useState(false);

  useEffect(() => {
    setHasSaveAsOption(hasLabel(options, entry.label));
    if (entry.state === 'new') {
      setIsDirty(true);
      setTimeout(() => setEditing(true), 0);
    } else {
      setIsDirty(false);
      setEditing(false);
    }
  }, [options, entry]);

  const handleEdit = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (entry.state !== 'readonly') {
      setEditing((prev) => !prev);
    }
  };

  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsDirty(false);
    const data = new FormData(e.currentTarget);
    const period = createPeriod24(
      entry.date,
      data.get('start') as string,
      data.get('end') as string
    );
    const label = data.get('category') as string;
    const tag = data.get('type') as string;
    const note = data.get('note') as string;
    const nonWork = options?.nonWork.find((x) => x.name === label);

    const updated: IEditableEntry = {
      shiftId: entry.shiftId,
      date: entry.date,
      startSeconds: period.start,
      durationSeconds: period.end - period.start,
      breakDurationSeconds: 0,

      label: label,
      note: note,
      tag: nonWork ? nonWork.abbr : tag,
      type: nonWork ? 'non-work' : 'work',
      code: nonWork ? nonWork.code : '',
      infoOnly: label === 'Nachtbereitschaft' ? true : entry.infoOnly,

      index: entry.index,
      total: entry.total,
      fromTracking: false,
      state: 'dirty'
    };
    props.save?.(entry, updated);
  };

  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    props.delete?.(props.entry);
  };

  const handleReset = () => {
    if (entry.state === 'new') {
      props.delete?.(entry);
    } else {
      setIsDirty(false);
      setHasSaveAsOption(hasLabel(options, entry.label));
    }
  };

  const handleChangeWorkType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setHasSaveAsOption(hasLabel(options, e.target.value));
  };

  return (
    <li
      className={`mb-4 cursor-pointer rounded-lg border py-2 transition-colors duration-1000 ${editing ? 'bg-silver-50 border-silver-200' : 'border-transparent'}`}
    >
      {entry.state !== 'new' && (
        <div className="mx-3 flex" onClick={handleEdit.bind(this)}>
          <div className="flex-1">
            <div className="text-xl font-medium">
              {formatStart(entry)} - {formatEnd(entry)}
            </div>
            <div className="text-sm text-black/50">
              {formatDuration(entry)} {entry.label}
            </div>
            {entry.note && (
              <div className="pl-2 text-sm italic text-black/50">
                &quot;{entry.note}
              </div>
            )}
          </div>

          {entry.state !== 'readonly' && (
            <div className="flex items-center gap-4 text-black/50">
              <IconButton color="ghost" onClick={handleEdit.bind(this)}>
                edit
              </IconButton>
              <IconButton color="ghost" onClick={handleDelete.bind(this)}>
                delete
              </IconButton>
            </div>
          )}
        </div>
      )}
      <Revealer show={editing}>
        {options && (
          <form
            className="mx-3 mt-4 flex flex-col gap-2"
            onChange={setIsDirty.bind(this, true)}
            onReset={handleReset.bind(this)}
            onSubmit={handleSave.bind(this)}
          >
            <div className="grid grid-cols-2 gap-2">
              <Input
                name="start"
                label="Von"
                defaultValue={formatStart(entry)}
                mask="time"
                autocomplete="off"
                disabled={!editing}
              />
              <Input
                name="end"
                label="Bis"
                defaultValue={formatEnd(entry)}
                mask="time"
                autocomplete="off"
                disabled={!editing}
              />
            </div>
            <div>
              <Select
                name="category"
                label="Kateogrie"
                defaultValue={entry.label}
                onChange={handleChangeWorkType.bind(this)}
                disabled={!editing}
              >
                <optgroup label="Dienstart">
                  {options.work.map((option, index) => (
                    <Option key={`dienstart-${index}`} value={option}>
                      {option}
                    </Option>
                  ))}
                </optgroup>
                <optgroup label="Nichtleistungszeiten">
                  {options.nonWork.map((option, index) => (
                    <Option key={`nlz-${index}`} value={option.name}>
                      {option.name}
                    </Option>
                  ))}
                </optgroup>
              </Select>
            </div>
            <Revealer show={hasSaveAsOption}>
              <Select
                name="type"
                label="Typ"
                defaultValue={entry.tag}
                disabled={!editing}
              >
                {options.saveAs.map((option) => (
                  <Option key={option.name} value={option.abbr}>
                    {option.name}
                  </Option>
                ))}
              </Select>
            </Revealer>
            <div>
              <Textarea
                name="note"
                label="Optionale Notizen"
                defaultValue={entry.note}
                disabled={!editing}
              />
            </div>
            <div className="my-1 flex justify-end gap-2">
              <GhostButton type="reset" disabled={!editing}>
                Abbrechen
              </GhostButton>
              <Button disabled={!isDirty || !editing} type="submit">
                Speichern
              </Button>
            </div>
          </form>
        )}
      </Revealer>
    </li>
  );
};
