import { IRosterTemplate } from '@kidsmanager/util-models';
import { IAuth, fetch } from '../auth';
import { BackendCache } from '../backend-cache';

const cache = new BackendCache();

export class ApiAdminRosters {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {
    this.templates = new ApiAdminRostersTemplates(auth, fetch);
  }
  templates: ApiAdminRostersTemplates;
}

class ApiAdminRostersTemplates {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {}

  async find(): Promise<IRosterTemplate[]> {
    return cache.get('roster_templates', async () => {
      const result = await this.fetch('api/admin/roster/template');
      if (result.ok) {
        return result.json();
      }
      return [];
    });
  }

  async add(): Promise<IRosterTemplate | undefined> {
    cache.clear('roster_templates');
    const result = await this.fetch('api/admin/roster/template', {
      method: 'POST'
    });
    if (result.ok) {
      return result.json();
    }
    return undefined;
  }

  async update(template: IRosterTemplate): Promise<IRosterTemplate> {
    cache.clear('roster_templates');
    const result = await this.fetch(
      `api/admin/roster/template/${template.id}`,
      {
        method: 'PUT',
        body: JSON.stringify(template)
      }
    );
    if (result.ok) {
      return result.json();
    }
    return template;
  }

  async delete(id: string): Promise<void> {
    cache.clear('roster_templates');
    await this.fetch(`api/admin/roster/template/${id}`, {
      method: 'DELETE'
    });
  }
}
