import { Revealer, ToggleButton } from '@kidsmanager/ui-core';
import { IShift, ISubShift } from '@kidsmanager/util-models';
import { useEffect, useState } from 'react';

type Slot = { label: string; disabled: boolean; active: boolean; from: number };
export interface NightDutyLoggerProps {
  shift: IShift;
  selected?: string[];
  onChange?: (selected: string[]) => void;
}

const buildSlotsFromShift = (
  onCall: ISubShift,
  nightshift: ISubShift,
  selected: string[] = []
): Slot[] => {
  const slots: Slot[] = [];
  const onCallStart = new Date().setTotalSeconds(onCall.start + 24 * 3600);
  const onCallEnd = new Date().setTotalSeconds(onCall.end + 24 * 3600);
  const nightEnd = new Date().setTotalSeconds(nightshift.end + 24 * 3600);
  let step = new Date().setTotalSeconds(nightshift.start);
  while (step < nightEnd) {
    const inOnCall = step >= onCallStart && step <= onCallEnd;
    const label = new Date(step).toTimeString().slice(0, 5);
    slots.push({
      label,
      disabled: !inOnCall,
      active: !inOnCall || selected.includes(label),
      from: nightshift.start + slots.length * 1800
    });
    step = new Date(step.getTime() + 30 * 60 * 1000);
  }
  return slots;
};

export const NightDutyLogger = (props: NightDutyLoggerProps) => {
  const [show, setShow] = useState(false);
  const [maxOnCallHours, setMaxOnCallHours] = useState(0);
  const [onCallHours, setOnCallHours] = useState(0);
  const [slots, setSlots] = useState<Slot[]>([]);

  useEffect(() => {
    if (!props.shift.onCall) {
      setShow(false);
    } else {
      const maxHours =
        (props.shift.onCall.end - props.shift.onCall.start) / 3600;
      setShow(true);
      setOnCallHours(maxHours);
      setMaxOnCallHours(maxHours);
      const newSlots = buildSlotsFromShift(
        props.shift.onCall,
        props.shift.nightshift,
        props.selected
      );
      setSlots(newSlots);
    }
  }, [props.shift, props.selected]);

  const onChange = (index: number, value: boolean) => {
    slots[index].active = value;
    setSlots([...slots]);
    props.onChange?.(
      slots.filter((s) => s.active && !s.disabled).map((s) => s.label)
    );

    if (!props.shift.onCall.fixed) {
      setOnCallHours(
        maxOnCallHours -
          slots.reduce((acc, slot) => (slot.active ? acc + 0.5 : acc), 0)
      );
    }
  };

  return (
    <Revealer show={show}>
      <div className="text-center">
        <div className="uppercase tracking-widest text-black/60">Nacht</div>
        <div className="mx-auto mt-2 flex max-w-md flex-wrap justify-center gap-1">
          {slots.map((slot, index) => (
            <ToggleButton
              key={index}
              color="primary"
              disabled={slot.disabled}
              active={slot.active}
              onChange={onChange.bind(this, index)}
            >
              <span className="px-2 leading-7">{slot.label}</span>
            </ToggleButton>
          ))}
        </div>
        <div className="my-2 font-light text-black/70">
          + {onCallHours.toFixed(1)}h Bereitschaft
        </div>
      </div>
    </Revealer>
  );
};
