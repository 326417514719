import { useNavigate, useParams } from 'react-router-dom';
import {
  DayValues,
  MonthCalendarView
} from './compoenents/month-calendar-view';
import { MonthSummary } from './compoenents/month-summary';
import { MonthDayDetails } from './compoenents/month-day-details';
import { TimesheetLowerHeader } from '../timesheet-common/timesheet-lower-header';
import { TimesheetStats } from '../timesheet-common/timesheet-stats';
import { createElement, useContext, useEffect, useState } from 'react';
import { DropdownMenu, IconButton } from '@kidsmanager/ui-core';
import { ClientBackendContext } from '@kidsmanager/ui-api';
import { IHoursForMonth } from 'libs/ui-api/src/lib/timesheet/api-timesheet-helper';
import { compileView, Filter, workingDays } from './helpers/view-compiler';
import { TimesheetPdf } from './helpers/timesheet-pdf';
import { pdf } from '@react-pdf/renderer';
import {
  mapAverageMonthSummary,
  mapBreakdown,
  mapCurrentMonthSummary,
  mapDaySummary,
  mapHolidaySummary
} from './helpers/mapper-day-summary';
import { extractHolidaysInMonth } from './helpers/mapper-helpers';
import { ContractedProRata, HolidayProRata } from '@kidsmanager/util-common';

export const TimesheetViewMonth = () => {
  const [carryForward, setCarryForward] = useState<number>(0);
  const [actual, setActual] = useState(0);
  const [adjustment, setAdjustment] = useState(0);
  const [contracted, setContracted] = useState<IHoursForMonth>();
  const [workedToDate, setWorkedToDate] = useState<number>(0);
  const [averagePlanned, setAveragePlanned] = useState<number>(0);
  const [selected, setSelected] = useState<Date>();
  const [date, setDate] = useState<Date>(new Date());
  const [days, setDays] = useState<DayValues[]>([]);
  const [workDays, setWorkDays] = useState(0);
  const [filter, setFilter] = useState<Filter>(
    (localStorage.getItem('timesheet-filter') as Filter) || 'both'
  );

  const params = useParams<{ userId: string; date: string }>();
  const client = useContext(ClientBackendContext);
  const navigate = useNavigate();

  useEffect(() => {
    const date = new Date(params.date || Date.now());
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const daysInMonth = new Date(year, month, 0).getDate();
    setDate(date);
    setDays(Array.from({ length: daysInMonth }, (_, i) => ({ values: [] })));
    Promise.all([
      client.timesheet.month(year, month, params.userId),
      client.timesheet.contracted(year, month, params.userId),
      client.holiday.publicHolidays(year),
      client.holiday.bookingsForYear(year, params.userId),
      client.roster.shifts.for(year, month, params.userId),
      client.roster.plan.released(year, month, params.userId)
    ]).then(([logged, contracted, publicHols, bookings, specs, plan]) => {
      const totalPlanned = logged.planned.takeAndSum(month - 1);
      const totalWorked = logged.worked.takeAndSum(month - 1);
      const totalAdj = logged.adjustments.takeAndSum(month - 1);
      const hols = extractHolidaysInMonth(bookings, year, month);
      const workCalc = new ContractedProRata(contracted.raw, 'hours');

      setAveragePlanned(workCalc.forYear(year, publicHols).monthly);
      setAdjustment(logged.adjustments[month - 1]);
      setCarryForward(totalAdj - (totalPlanned - totalWorked));
      setContracted(contracted);
      setDays(
        compileView(date, bookings, publicHols, specs, plan, logged, filter)
      );
      setWorkDays(workingDays(date, publicHols));
      setActual(logged.daysTotal + hols * contracted.default);
      setWorkedToDate(
        totalWorked + logged.daysTotal + hols * contracted.default
      );
    });
  }, [params.date, params.userId, client, filter]);

  const onClicked = (date?: Date) => {
    setSelected((prev) => {
      if (!date) return undefined;
      return prev?.getTime() === date.getTime() ? undefined : date;
    });
  };

  const onFilterChanged = (value: string) => {
    setFilter(value as Filter);
    localStorage.setItem('timesheet-filter', value);
  };

  const onMove = (delta: number) => {
    const d = new Date(
      date.getFullYear(),
      date.getMonth() + delta,
      date.getDate()
    );
    navigate(`/work/${params.userId}/${d.toLocaleISODate()}/month`);
  };

  const handleUserSelected = (userId: string) => {
    navigate(`/work/${userId}/${params.date}/month`);
  };

  const handlePrint = async (userId?: string) => {
    if (userId) {
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const [
        user,
        monthData,
        loggedDays,
        contracted,
        allowances,
        bookings,
        publicHols
      ] = await Promise.all([
        client.user.find(userId),
        client.timesheet.month(year, month, params.userId),
        client.timesheet.days(year, month, userId),
        client.timesheet.contracted(year, month, userId),
        client.holiday.allowances(params.userId),
        client.holiday.bookingsForYear(year, userId),
        client.holiday.publicHolidays(year)
      ]);

      const days = mapDaySummary(loggedDays, bookings, publicHols, contracted);
      const breakdown = mapBreakdown(days);
      const holiday = mapHolidaySummary(
        year,
        month,
        bookings,
        publicHols,
        new HolidayProRata(allowances, 'days').forYear(year)
      );
      const current = mapCurrentMonthSummary(
        year,
        month,
        monthData,
        actual,
        carryForward,
        adjustment,
        contracted,
        workDays
      );
      const average = mapAverageMonthSummary(
        year,
        month,
        monthData,
        actual,
        adjustment,
        contracted,
        new ContractedProRata(contracted.raw, 'hours').forYear(year, publicHols)
      );

      const blob = await pdf(
        createElement(() => (
          <TimesheetPdf
            date={date}
            user={user}
            days={days}
            breakdown={breakdown}
            holiday={holiday}
            current={current}
            average={average}
          />
        ))
      ).toBlob();
      window.open(URL.createObjectURL(blob), '_blank');
    }
  };

  return (
    <>
      <TimesheetLowerHeader onUserSelected={handleUserSelected.bind(this)}>
        <div className="flex items-center px-4 text-center text-2xl sm:block sm:text-3xl">
          <span
            className="material-icons cursor-pointer hover:text-black/50"
            style={{ userSelect: 'none' }}
            onClick={onMove.bind(this, -1)}
          >
            arrow_back_ios
          </span>
          <span className="inline-block min-w-36 md:mx-2">
            {date
              .toLocaleString('de-DE', { month: 'short', year: 'numeric' })
              .replace('.', '')}
          </span>
          <span
            className="material-icons cursor-pointer hover:text-black/50"
            style={{ userSelect: 'none' }}
            onClick={onMove.bind(this, 1)}
          >
            arrow_forward_ios
          </span>
        </div>
        <TimesheetStats
          month={date.getMonth() + 1}
          planned={workDays * (contracted?.hoursPerDay || 0) - carryForward}
          averagePlanned={averagePlanned}
          actual={actual}
          workedToDate={workedToDate}
        />
      </TimesheetLowerHeader>
      <div className="p-2">
        <div className="flex items-center pb-2">
          <div className="flex-1 text-xs">
            <DropdownMenu
              selected={filter}
              options={[
                { key: 'logged', label: 'Eingetragene' },
                { key: 'planned', label: 'Geplante' },
                { key: 'both', label: 'Geplante & Eingetragene' }
              ]}
              onChange={onFilterChanged.bind(this)}
            />
          </div>
          <IconButton
            color="ghost"
            onClick={handlePrint.bind(this, params.userId)}
          >
            print
          </IconButton>
        </div>
        <MonthCalendarView
          date={date}
          days={days}
          onClick={onClicked.bind(this)}
          selected={selected}
          userId={params.userId}
        />
      </div>
      <div className="flex justify-center py-10">
        <div className="max-w-lg flex-1">
          {selected && (
            <MonthDayDetails userId={params.userId} date={selected} />
          )}
          {!selected && (
            <MonthSummary
              date={date}
              workDays={workDays}
              carryForward={carryForward}
              hoursPerWeek={5 * (contracted?.hoursPerDay || 0)}
              actual={actual}
              planned={workDays * (contracted?.hoursPerDay || 0)}
              correction={adjustment}
            />
          )}
        </div>
      </div>
    </>
  );
};
