import '@kidsmanager/util-extensions';
import { Checkbox, Revealer } from '@kidsmanager/ui-core';
import { useMemo } from 'react';

export interface YesterdayToggleProps {
  date: Date;
  hide?: boolean;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
}

export const YesterdayToggle = (props: YesterdayToggleProps) => {
  const show = useMemo(() => {
    const isToday = props.date.dateMatches(new Date());
    return isToday && !props.hide;
  }, [props.date, props.hide]);

  return (
    <Revealer show={show}>
      <div className="text-center">
        <Checkbox checked={props.checked} onChange={props.onChange?.bind(this)}>
          Gestern gestartet
        </Checkbox>
      </div>
    </Revealer>
  );
};
