export interface RevealerProps {
  children: React.ReactNode;
  show: boolean;
}

export const Revealer = (props: RevealerProps) => {
  return (
    <div
      className="grid transition-[grid-template-rows] duration-500 ease-in-out"
      style={{ gridTemplateRows: props.show ? '1fr' : '0fr' }}
    >
      <div className="-m-1 overflow-hidden p-1">{props.children}</div>
    </div>
  );
};
