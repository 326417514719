import { useEffect, useState } from 'react';
import { Slider } from '../slider/slider';

export interface DateSliderProps {
  nominal: Date;
  value: Date;
  plus: number;
  minus: number;
  color?: 'primary' | 'accent';
  invert?: boolean;
  onChange?: (value: Date) => void;
}

const valueToDate = (value: Date) =>
  value
    .toLocaleString('de-DE', { day: '2-digit', month: 'short' })
    .replace('.', '');

const valueToTime = (value: Date) =>
  value
    .toLocaleString('de-DE', { hour: '2-digit', minute: '2-digit' })
    .replace('.', '');

export const DateSlider = (props: DateSliderProps) => {
  const [start, setStart] = useState<Date>(new Date());
  const [range, setRange] = useState<number>(0);
  const [value, setValue] = useState<number>(0);
  const [date, setDate] = useState<string>();
  const [time, setTime] = useState<string>();

  useEffect(() => {
    setStart(new Date(props.nominal));

    // below is a little trick to trigger a reset of the slider
    // when the nominal date changes but the range remains the same
    setRange((prev) => prev + 1);
    setTimeout(() => setRange(props.plus + props.minus), 10);
  }, [props.plus, props.minus, props.nominal]);

  useEffect(() => {
    setValue(range / 2);
  }, [range]);

  useEffect(() => {
    setDate(valueToDate(props.value));
    setTime(valueToTime(props.value));
  }, [props.value]);

  const onChange = (minutes: number) => {
    const adj = (minutes - range / 2) * 60000;
    props.onChange?.(new Date(start.getTime() + adj));
  };

  return (
    <div className="text-center">
      <div className="text-[28px] leading-7">{time}</div>
      <div className="mb-2 opacity-60">{date}</div>
      <Slider
        min={0}
        max={range}
        step={15}
        value={value}
        invert={props.invert}
        color={props.color}
        onChange={onChange.bind(this)}
      />
    </div>
  );
};
