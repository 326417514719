import { IEntry, IHolidayBooking } from '@kidsmanager/util-models';
import '@kidsmanager/util-extensions';

const nightShift = ['nachtdienst'];
const nightStandby = ['nachtbereitschaft', 'nachtbereitshaft'];

export const toTime = (seconds: number) => {
  const hours = Math.floor(seconds / (60 * 60));
  const mins = Math.floor((seconds - hours * 60 * 60) / 60);
  return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
};

export const periodIncludes = (
  period: { from: Date; to: Date },
  date: Date
) => {
  return date >= period.from && date <= period.to;
};

export const extractTimes = (date: Date, entries: IEntry[]) => {
  const first = entries[0];
  const last = entries[entries.length - 1];

  if (first.startSeconds === 0) {
    return { from: 0, until: 0, times: '' };
  }

  const offset =
    new Date(first.date).getMonth() < 9 ? first.startSeconds * 1000 : 0;
  const tzAdj = new Date(date.getTime() + offset).getTimezoneOffset() * 60;
  const from = first.startSeconds - tzAdj;
  const until = last.startSeconds - tzAdj + last.durationSeconds;
  return { from, until, times: `${toTime(from)} - ${toTime(until)}` };
};

export const extractWorked = (entries: IEntry[]) => {
  return entries
    .filter((x) => x.type !== 'non-work' && !x.infoOnly)
    .map((x) => x.durationSeconds - x.breakDurationSeconds)
    .reduce((sum, x) => sum + x, 0);
};

export const extractStandby = (entries: IEntry[]) => {
  let timeChange = 0;
  const standby = entries
    .filter((x) => nightStandby.includes(x.label.toLowerCase()))
    .map((x) => {
      const time = new Date(x.date).getTime();
      const start = new Date(time + x.startSeconds * 1000);
      const end = new Date(time + (x.startSeconds + x.durationSeconds) * 1000);
      timeChange += (end.getTimezoneOffset() - start.getTimezoneOffset()) * 60;
      return x.durationSeconds + timeChange;
    })
    .reduce((sum, x) => sum + x, 0);
  return { standby, timeChange };
};

export const extractNightshift = (entries: IEntry[]) => {
  return entries
    .filter((x) => nightShift.includes(x.label.toLowerCase()))
    .map((x) => x.durationSeconds)
    .reduce((sum, x) => sum + x, 0);
};

export const extractFreetime = (
  entries: IEntry[],
  publicHols: Date[]
): number => {
  return entries
    .filter((x) => {
      const d = new Date(x.date);
      const isWork = x.type === 'work';
      const isSunday = d.getDay() === 0;
      const isPublicHoliday = publicHols.some((ph) => ph.dateMatches(d));
      const isNotNightShift = !nightShift.includes(x.label.toLowerCase());
      return (isSunday || isPublicHoliday) && isWork && isNotNightShift;
    })
    .map((x) => x.durationSeconds)
    .reduce((sum, x) => sum + x, 0);
};

export const extractNotes = (entries: IEntry[]) => {
  return entries
    .map((x) => x.note)
    .filter((x) => x && x.length > 0)
    .join(', ');
};

export const extractTags = (entries: IEntry[]) => {
  return entries.reduce((acc: string[], x) => {
    if (x.tag.length && !acc.includes(x.tag)) {
      acc.push(x.tag);
    }
    return acc;
  }, []);
};

export const extractNonWork = (entries: IEntry[]) => {
  return entries
    .filter((x) => x.type === 'non-work')
    .map((x) => x.durationSeconds - x.breakDurationSeconds)
    .reduce((sum, x) => sum + x, 0);
};

export const extractInfoOnly = (entries: IEntry[]) => {
  return entries
    .filter((x) => x.type === 'non-work' && x.infoOnly)
    .map((x) => x.durationSeconds)
    .reduce((sum, x) => sum + x, 0);
};

export const extractSickLeave = (entries: IEntry[]) => {
  return entries
    .filter((x) => x.tag === 'KS' || x.tag === 'gKS')
    .map((x) => x.durationSeconds)
    .reduce((sum, x) => sum + x, 0);
};

export const extractHolidaysInMonth = (
  bookings: IHolidayBooking[],
  year: number,
  month: number
) => {
  // we have a problem with UTC dates here
  const start = new Date(Date.UTC(year, month - 1, 1));
  const end = new Date(Date.UTC(year, month, 0));
  const relevant = bookings.filter((b) => b.from >= start && b.to <= end);
  const days = Array.from({ length: end.getDate() }, (_, i) => {
    const day = new Date(Date.UTC(year, month - 1, i + 1));
    if (day.isWeekend()) {
      return 0;
    }
    return relevant.find((b) => periodIncludes(b, day)) ? 1 : (0 as number);
  }).reduce((acc, x) => acc + x, 0);
  return days;
};

export const holidaysToMonth = (
  holidays: IHolidayBooking[],
  publicHols: Date[],
  year: number,
  month: number
) => {
  if (month === 0) {
    return 0;
  }
  const start = new Date(year, 0, 1);
  const end = new Date(year, month, 1);

  let totalDays = 0;
  holidays.forEach((booking) => {
    if (booking.from >= end) return; //date after current month

    const from = booking.from >= start ? booking.from : start;
    const until = booking.to <= end ? booking.to : end;
    totalDays += from.getWorkingDaysUntil(until, publicHols);
  });
  return totalDays;
};
