import '@kidsmanager/util-extensions';
import { CalendarDay, CalendarWeek } from './date-types';

export const generateWeeks = (value: Date, publicHolidays: Date[]) => {
  const hols = (publicHolidays || []).map((x) => x.getTime());
  const startOfMonth = new Date(value.getFullYear(), value.getMonth(), 1);
  const endOfMonth = new Date(value.getFullYear(), value.getMonth() + 1, 0);
  const dayOffset = startOfMonth.getDay() - 1;

  const days: CalendarDay[] = new Array(
    endOfMonth.getDate() + startOfMonth.getDay() - 1
  )
    .fill(undefined)
    .map((_, i) => {
      const date = new Date(
        value.getFullYear(),
        value.getMonth(),
        i - dayOffset + 1
      );
      return {
        date,
        label: i - dayOffset >= 0 ? (i - dayOffset + 1).toString() : '',
        publicHoliday:
          i - dayOffset >= 0 ? hols.includes(date.getTime()) : false
      };
    });

  return days.reduce((acc, day, index) => {
    if (index % 7 === 0) {
      if (!day.date) {
        return acc;
      }
      const start = day.date;
      const week = day.date.getWeek();
      const end = new Date(start.getTime() + 6 * 24 * 60 * 60 * 1000);
      acc.push({ week, start, end, days: [] });
    }
    acc[acc.length - 1].days.push(day);
    return acc;
  }, [] as CalendarWeek[]);
};

export const weekHighlight = (week: CalendarWeek, from?: Date, to?: Date) => {
  const start = week.days.find((x) => x.label)?.date || week.start;
  const end =
    week.days
      .filter((x) => x.label)
      .reverse()
      .at(0)?.date || week.end;

  if (!from || !to || start > to || end < from)
    return { start: 0, end: 0, enabled: false };

  const result = { start: 0, end: 0, enabled: true };

  if (from >= start && from <= end) {
    const i = week.days.findIndex((day) => day.selected);
    const start = i + 0.5;
    result.start = start;
    if (i >= week.days.length - 1) {
      return result;
    }
  }

  if (to >= start && to <= end) {
    for (let i = week.days.length - 1; i >= 0; i--) {
      if (week.days[i].selected) {
        result.end = 6.5 - i;
        break;
      }
    }
  }
  return result;
};
