const randomColors = ['#FCA805', '#2A97E5', '#05B5FC', '#41467D', '#416C7D'];

export const nameToColor = (value: string | undefined) => {
  if (!value) return '#ddd';
  value = value.charAt(0).toLowerCase();
  switch (value) {
    case 'b':
      return '#6BC131';
    case 'h':
      return '#5927C1';
    case 't':
      return '#FADE7D';
    default: {
      return randomColors[value.charCodeAt(0) % 5];
    }
  }
};
