import '@kidsmanager/util-extensions';
import { CalendarWeek } from './date-types';
import { useMemo } from 'react';
import { weekHighlight } from './date-helpers';

export interface DateCalendarProps {
  outline?: Date;
  mode?: 'day' | 'week';
  weeks: CalendarWeek[];
  highlight?: { from?: Date; to?: Date };
  onClicked?: (e: React.MouseEvent, date: Date | undefined) => void;
}

export const DateCalendar = (props: DateCalendarProps) => {
  const { mode, weeks } = props;

  const highlights = useMemo(
    () =>
      weeks.map((week) =>
        weekHighlight(week, props.highlight?.from, props.highlight?.to)
      ),
    [props.highlight, weeks]
  );

  return (
    <div>
      <div
        className={`${mode === 'week' ? 'grid' : null} grid-cols-[2.5rem_auto] items-center`}
      >
        {mode === 'week' && <div className="w-10"></div>}
        <div className="grid h-5 grid-cols-7" style={{ userSelect: 'none' }}>
          {['M', 'D', 'M', 'D', 'F', 'S', 'S'].map((label, index) => (
            <div key={index} className="text-center text-xs opacity-50">
              {label}
            </div>
          ))}
        </div>
      </div>

      {weeks.map((week, i) => (
        <div
          key={`week-${i}`}
          className={`${mode === 'week' ? 'grid' : null} grid-cols-[2.5rem_auto] items-center`}
        >
          {mode === 'week' && (
            <div className="w-10 pl-2 text-xs opacity-60">{week.week}</div>
          )}
          <div className="relative grid grid-cols-7">
            {highlights[i].enabled && (
              <div
                id="Highlight"
                className="bg-accent absolute inset-1 -z-10 opacity-30"
                style={{
                  left: `${(highlights[i].start * 100) / 7}%`,
                  right: `${(highlights[i].end * 100) / 7}%`
                }}
              />
            )}
            {week.days.map((day, j) => (
              <div key={`day-${i}-${j}`}>
                <div
                  className={`border-accent mx-auto max-w-16 cursor-pointer rounded-full border text-center leading-8 ${day.label ? 'hover:bg-neutral-100' : ''}`}
                  style={{
                    borderColor: props.outline?.dateMatches(day.date)
                      ? 'var(--color-accent)'
                      : 'transparent',
                    backgroundColor: day.selected
                      ? '#444444'
                      : day.publicHoliday
                        ? '#1c9e76aa'
                        : '',
                    color: day.selected || day.publicHoliday ? 'white' : '',
                    userSelect: 'none'
                  }}
                  onClick={(e) => day.label && props.onClicked?.(e, day.date)}
                >
                  {day.publicHoliday ? 'FT' : day.label}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
