import { useMemo } from 'react';

export interface TimesheetStatsProps {
  actual: number;
  planned: number;
  month?: number;
  averagePlanned?: number;
  workedToDate?: number;
}

export const TimesheetStats = ({
  month,
  actual,
  planned,
  averagePlanned,
  workedToDate
}: TimesheetStatsProps) => {
  const averageDiff = useMemo(() => {
    if (
      workedToDate === undefined ||
      month === undefined ||
      averagePlanned === undefined
    ) {
      return 0;
    }
    return workedToDate - month * averagePlanned;
  }, [workedToDate, month, averagePlanned]);

  return (
    <div>
      <div className="text-center text-3xl">{actual.toFixed(1)}</div>
      <div className="flex items-center justify-center gap-1 text-sm">
        <span>SOLL {planned.toFixed(1)}h</span>
        {actual !== planned && (
          <>
            <span className="material-icons" style={{ fontSize: '16px' }}>
              {actual <= planned ? 'arrow_downward' : 'arrow_upward'}
            </span>
            <span>{Math.abs(planned - actual).toFixed(1)}</span>
          </>
        )}
      </div>
      {averagePlanned && (
        <div className="flex items-center justify-center gap-1 text-sm">
          <span>&Oslash; SOLL {averagePlanned.toFixed(1)}h</span>
          {actual !== averagePlanned && (
            <>
              <span className="material-icons" style={{ fontSize: '16px' }}>
                {averageDiff < 0 ? 'arrow_downward' : 'arrow_upward'}
              </span>
              <span>{Math.abs(averageDiff).toFixed(1)}</span>
            </>
          )}
        </div>
      )}
    </div>
  );
};
