import { NavigateFunction } from 'react-router-dom';

export type onLoad = ((this: GlobalEventHandlers, ev: Event) => unknown) | null;

export const linkUrl = (
  tenant: string,
  otp: string,
  origin: string,
  rememberMe: boolean
) =>
  `https://${tenant}.kidsmanager.at/account/link?otp=${otp}&tenant=${tenant}&rememberMe=${rememberMe}&origin=${origin}`;

export const unlinkUrl = (tenant: string) =>
  `https://${tenant}.kidsmanager.at/account/unlink`;

export const linkWithLegacy = (
  tenant: string,
  otp: string,
  origin: string,
  rememberMe: boolean,
  returnUrl: string,
  navigate: NavigateFunction,
  callbackDone?: () => void
) => {
  if (tenant === 'dev') {
    callbackDone?.();
    console.log('Navigating to local return url', returnUrl);
    navigate(returnUrl);
  } else {
    const debug = localStorage.getItem('debug-auth') === 'true';
    const iframe = document.createElement('iframe');
    iframe.id = 'link-iframe';
    iframe.style.display = debug ? 'block' : 'none';
    iframe.onload = () => {
      callbackDone?.();
      if (debug) {
        alert(`continue manually to ${tenant}`);
      } else {
        console.log(`Redirecting to tenant: ${tenant}`);
        window.location.href = `https://${tenant}.kidsmanager.at/`;
      }
      document.getElementById('link-iframe')?.remove();
    };
    iframe.onerror = console.error;
    iframe.src = linkUrl(tenant, otp, origin, rememberMe);
    document.body.appendChild(iframe);
  }
};

export const unlinkWithLegacy = (tenant: string, callbackDone?: () => void) => {
  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  iframe.onload = () => {
    console.log('unlink done');
    callbackDone?.();
  };
  iframe.src = unlinkUrl(tenant);
  document.body.appendChild(iframe);
};

export const redirectUrl = (
  linkWithLegacy?: boolean,
  returnUrl?: string
): string => {
  if (linkWithLegacy) {
    return returnUrl
      ? `/login/legacy?returnUrl=${encodeURIComponent(returnUrl)}`
      : '/login/legacy';
  } else {
    return returnUrl ? returnUrl : '/';
  }
};
