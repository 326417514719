import {
  IMembership,
  IMemberStats,
  ITeamConfig
} from '@kidsmanager/util-models';
import { IAuth, fetch } from '../auth';
import { BackendCache } from '../backend-cache';

const cache = new BackendCache();

export class ApiRosterTeam {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {}

  async config(group: string): Promise<ITeamConfig> {
    return cache.get(`team-${group}-config`, async () => {
      return (await this.fetch(`api/roster/${group}/team/config`)).json();
    });
  }

  async members(
    groupId: string,
    year: number,
    month: number
  ): Promise<IMembership[]> {
    const iso_month = new Date(year, month - 1).toLocaleISOMonth();
    return cache.get(`team-${groupId}-${iso_month}`, async () => {
      return (
        await this.fetch(`api/roster/${groupId}/team?month=${iso_month}`)
      ).json();
    });
  }

  async stats(
    groupId: string,
    year: number,
    month: number,
    members: string[]
  ): Promise<IMemberStats[]> {
    if (!members.length) {
      return [];
    }

    const iso_month = new Date(year, month - 1).toLocaleISOMonth();
    return cache.get(
      `team-${groupId}-${iso_month}-${members.join('-')}`,
      async () => {
        return (
          await this.fetch(
            `api/roster/${groupId}/team/stats?month=${iso_month}${members.map((member) => `&id=${member}`).join('')}`
          )
        ).json();
      }
    );
  }
}
