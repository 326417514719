import { ClientBackendContext } from '@kidsmanager/ui-api';
import { Button } from '@kidsmanager/ui-core';
import { IHolidayBooking } from '@kidsmanager/util-models';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const HolidayViewBooking = () => {
  const [holiday, setHoliday] = useState<IHolidayBooking>();
  const [days, setDays] = useState(0);

  const params = useParams<{ start: string; userId: string }>();
  const client = useContext(ClientBackendContext);
  const navigate = useNavigate();

  useEffect(() => {
    const start = new Date(params.start || Date.now());
    const year = start.getFullYear();

    Promise.all([
      client.holiday.booking(start, params.userId),
      client.holiday.publicHolidays(year - 1, year, year + 1)
    ]).then(([booking, publicHolidays]) => {
      setHoliday(booking);
      setDays(
        () => booking?.from.workingDaysUntil(booking.to, publicHolidays) || 0
      );
    });
  }, [client, params.start, params.userId]);

  const formatDate = (date: Date) =>
    date.toLocaleString('de-DE', {
      weekday: 'short',
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });

  const shortDate = (date: string | null) =>
    new Date(date || 0).toLocaleString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });

  const handleCancelRequest = async () => {
    if (!holiday) {
      return;
    }
    await client.holiday.cancelRequest(holiday.from, params.userId);
    navigate(-1);
  };

  const handleApproveRequest = async () => {
    setHoliday((prev) => {
      if (!prev) {
        return prev;
      }
      const by = client.auth.displayName() || '';
      const when = new Date().toLocaleISODate();
      client.holiday.approveRequest(prev.from, by, when, params.userId);
      return {
        ...prev,
        approvedBy: by,
        approvedWhen: when
      };
    });
  };

  const handleUnapproveRequest = async () => {
    setHoliday((prev) => {
      if (!prev) {
        return prev;
      }
      client.holiday.unapproveRequest(prev.from, params.userId);
      return {
        ...prev,
        approvedBy: null,
        approvedWhen: null
      };
    });
  };

  return !holiday ? undefined : (
    <div>
      <table className="mx-auto mt-8 w-full max-w-md">
        <tbody>
          <tr>
            <td className="pb-4 font-medium">Von</td>
            <td className="pb-4 text-right">{formatDate(holiday.from)}</td>
          </tr>
          <tr>
            <td className="pb-4 font-medium">Bis</td>
            <td className="pb-4 text-right">{formatDate(holiday.to)}</td>
          </tr>
          <tr>
            <td className="pb-4 font-medium">Urlaubstage</td>
            <td className="pb-4 text-right">{days}</td>
          </tr>
          <tr>
            <td className="pb-4 font-medium">Status</td>
            <td className="pb-4 text-right">
              {holiday.approvedWhen ? (
                <span>Genehmigt</span>
              ) : (
                <span>Best&auml;tigung ausstehend</span>
              )}
            </td>
          </tr>
          {holiday.approvedWhen && (
            <>
              <tr>
                <td className="pb-4 pl-4 font-medium">Durch</td>
                <td className="pb-4 text-right">{holiday.approvedBy}</td>
              </tr>
              <tr>
                <td className="pb-4 pl-4 font-medium">Am</td>
                <td className="pb-4 text-right">
                  {shortDate(holiday.approvedWhen)}
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>
      <div className="mx-auto mt-10 flex max-w-80 flex-col gap-2">
        {!holiday.approvedWhen && params.userId !== 'my' && (
          <Button
            className="uppercase"
            onClick={handleApproveRequest.bind(this)}
          >
            Genehmigen
          </Button>
        )}
        {holiday.approvedWhen && params.userId !== 'my' && (
          <Button
            className="uppercase"
            onClick={handleUnapproveRequest.bind(this)}
          >
            Genehmigung aufheben
          </Button>
        )}

        <Button
          disabled={!!holiday.approvedWhen}
          className="uppercase"
          onClick={handleCancelRequest.bind(this)}
        >
          Stornieren
        </Button>
      </div>
    </div>
  );
};
