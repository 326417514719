import { IEntry } from '@kidsmanager/util-models';

export const formatStart = (entry: IEntry) => {
  const start = new Date(
    new Date(entry.date).getTime() + entry.startSeconds * 1000
  );
  return start.toLocaleTimeString('de-DE', {
    hour: '2-digit',
    minute: '2-digit'
  });
};

export const formatEnd = (entry: IEntry) => {
  const end = new Date(
    new Date(entry.date).getTime() +
      (entry.startSeconds + entry.durationSeconds) * 1000
  );
  return end.toLocaleTimeString('de-DE', {
    hour: '2-digit',
    minute: '2-digit'
  });
};

export const formatDuration = (entry: IEntry) => {
  const hours = Math.floor(entry.durationSeconds / 3600);
  const mins = Math.floor((entry.durationSeconds % 3600) / 60);
  return mins === 0
    ? `${hours}h`
    : `${hours}h${mins.toString().padStart(2, '0')}`;
};

export const formatBreak = (entry: IEntry) => {
  return entry.breakDurationSeconds > 0
    ? `${entry.breakDurationSeconds / 3600}h Pausen`
    : '';
};
