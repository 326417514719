export const shortCityCodes: string[] = [
  '1', // Vienna
  '316', // Graz
  '732', // Linz
  '662', // Salzburg
  '512', // Innsbruck
  '463', // Klagenfurt,

  '660', // Magenta
  '664', // A1
  '676', // A1
  '677', // Magenta
  '681', // Drei
  '688', // Drei
  '699' // Magenta
];
