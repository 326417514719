export interface MonthViewProps {
  year: number;
  month: number;
  onChange?: (year: number, month: number) => void;
}

export const MonthPickerView = (props: MonthViewProps) => {
  const months = [
    'Jan',
    'Feb',
    'Mär',
    'Apr',
    'Mai',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Dez'
  ];

  return (
    <div className="w-52 text-black">
      <div className="my-2 flex justify-around text-sm">
        <button
          className="outline-focus px-2 text-black/40 underline"
          onClick={() => props.onChange?.(props.year - 1, props.month)}
        >
          {props.year - 1}
        </button>
        <button className="px-2" tabIndex={-1}>
          {props.year}
        </button>
        <button
          className="outline-focus px-2 text-black/40 underline"
          onClick={() => props.onChange?.(props.year + 1, props.month)}
        >
          {props.year + 1}
        </button>
      </div>
      <div className="mx-2 my-4 grid grid-cols-3 gap-2 text-base">
        {months.map((month, index) => (
          <button
            key={month}
            className={`outline-focus cursor-pointer rounded-full border py-1 text-center text-sm ${
              props.month === index + 1 ? 'bg-neutral-400 text-white' : ''
            }`}
            onClick={() => props.onChange?.(props.year, index + 1)}
          >
            {month}
          </button>
        ))}
      </div>
    </div>
  );
};
