import { useContext } from 'react';
import { DrawerContext } from '@kidsmanager/ui-core';
import { Outlet } from 'react-router-dom';

export const AbsenceManager = () => {
  const drawer = useContext(DrawerContext);

  drawer.configure('Nichtleistungszeiten');

  return <Outlet />;
};
