import { useMemo } from 'react';

export interface MonthSummaryProps {
  date: Date;
  carryForward: number;
  actual: number;
  planned: number;
  hoursPerWeek: number;
  workDays: number;
  correction: number;
}

export const MonthSummary = ({
  date,
  carryForward,
  actual,
  planned,
  hoursPerWeek,
  workDays,
  correction
}: MonthSummaryProps) => {
  const isInPast = useMemo(() => date < new Date(), [date]);

  return (
    <table className="w-full">
      <tbody>
        <tr>
          <td>Vorheriger Monat</td>
          <td className="text-right">{carryForward.toFixed(2)}</td>
        </tr>
        <tr>
          <td>
            Geplant [{hoursPerWeek.toFixed(1)} pro Woche, {workDays} Tage]
          </td>
          <td className="text-right">{planned.toFixed(2)}</td>
        </tr>
        <tr>
          <td>Gearbeitet</td>
          <td className="text-right">{actual.toFixed(2)}</td>
        </tr>
        {correction !== 0 && (
          <tr>
            <td>LV Korr.</td>
            <td className="text-right">{correction.toFixed(2)}</td>
          </tr>
        )}
        {isInPast && (
          <tr>
            <td>&Uuml;bertrag</td>
            <td className="text-right">
              {(carryForward + actual + correction - planned).toFixed(2)}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
