import React, { useState } from 'react';

export interface DropdownMenuProps {
  selected?: string;
  options: { key: string; label: string }[];
  onChange?: (value: string) => void;
}

export const DropdownMenu = (props: DropdownMenuProps) => {
  const [selected, setSelected] = useState<{ key: string; label: string }>();

  React.useEffect(() => {
    if (props.selected) {
      const found = props.options.find(
        (option) => option.key === props.selected
      );
      if (found) {
        setSelected(found);
        return;
      }
    }
    setSelected(props.options[0]);
  }, [props.selected, props.options]);

  const onSelected = (value: { key: string; label: string }) => {
    if (props.onChange) {
      props.onChange(value.key);
    } else {
      setSelected(value);
    }
    (document.activeElement as HTMLElement).blur();
  };

  return (
    <div className="">
      <div
        tabIndex={0}
        className="outline-focus group relative inline-flex cursor-pointer items-center px-1"
      >
        <span className="text-xs text-black/60">{selected?.label}</span>
        <span className="material-icons text-xs text-black/60">
          arrow_drop_down
        </span>
        <ul className="shadow-hdr border-silver-300 absolute left-0 top-6 z-50 hidden border bg-white py-2 text-left group-focus-within:block">
          {props.options.map((option) => (
            <li
              key={option.key}
              className={`text-hdr min-w-14 text-nowrap px-4 py-2 hover:bg-stone-200 ${selected === option ? 'font-bold' : ''}`}
              onClick={onSelected.bind(this, option)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
