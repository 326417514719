import { IValueFrom } from '@kidsmanager/util-models';

export interface IHoursForMonth {
  /** sum of hours for the month */
  hoursPerDay: number;
  hoursPerWeek: number;

  /* working days in month */
  days: number;
  /* days that the user was working in the month */
  present: number;
  /* hours expected if the user was to work all working days */
  default: number;

  raw: IValueFrom[];
}

export const hoursForMonth = (
  values: IValueFrom[],
  year: number,
  month: number
): IHoursForMonth => {
  const result: IHoursForMonth = {
    hoursPerWeek: 0,
    hoursPerDay: 0,
    days: 0,
    present: 0,
    default: 0,
    raw: []
  };

  if (values.length === 0) {
    return result;
  }

  const startMonthExpand = (
    values: IValueFrom[],
    year: number,
    month: number
  ) => {
    const [startYear, startMonth] = values[0].from.split('-').map(Number);
    return startYear === year && startMonth === month
      ? values[0].hours / 5.0
      : 0;
  };

  let hoursPerMonth = 0;
  let dayOfMonth = new Date(Date.UTC(year, month, 1));
  while (dayOfMonth.getMonth() === month) {
    if (dayOfMonth.getDay() !== 0 && dayOfMonth.getDay() !== 6) {
      result.days += 1;
      if (values.length < 0) {
        continue;
      }
      const dom = dayOfMonth;
      const index = findLastIndex(values, (x) => x <= dom);
      if (index >= 0) {
        result.present += 1;
        result.default += values[index].hours / 5.0;
        hoursPerMonth += values[index].hours / 5.0;
      } else {
        result.default += startMonthExpand(values, year, month);
      }
    }
    const ticksPerDay = 86400000;
    dayOfMonth = new Date(dayOfMonth.getTime() + ticksPerDay);
  }
  result.hoursPerDay = hoursPerMonth / result.days;
  result.hoursPerWeek = result.hoursPerDay * 5;
  result.default = result.default / result.days;
  result.raw = values;
  return result;
};

const findLastIndex = (
  values: IValueFrom[],
  predicate: (x: Date) => boolean
): number => {
  for (let i = values.length - 1; i >= 0; i--) {
    const parsed = new Date(values[i].from);
    if (predicate(parsed)) {
      return i;
    }
  }
  return -1;
};
