import useMediaQuery from './use-media-query';

export interface MobileAwareLayoutProps {
  desktop: JSX.Element;
  mobile: JSX.Element;
  callback?: (mode: 'desktop' | 'mobile') => void;
}

export const MobileAwareLayout = (props: MobileAwareLayoutProps) => {
  const isMobile = useMediaQuery();
  return isMobile ? props.mobile : props.desktop;
};
