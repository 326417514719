import { shortCityCodes } from './city-codes';
import { MaskOptions } from './mask-options';

export const applyBlur = (
  value: string,
  type: 'text' | 'number' | 'password' | undefined,
  min: number | undefined,
  max: number | undefined,
  mask: MaskOptions
) => {
  if (mask === 'time') {
    return applyTimeBlur(value);
  }
  if (mask === 'date') {
    return applyDateBlur(value);
  }
  if (mask === 'day') {
    return applyDayBlur(value);
  }
  if (mask === 'phone') {
    return applyPhoneBlur(value);
  }

  if (type === 'number' || mask === 'number') {
    const num = parseFloat(value);
    if (isNaN(num)) {
      return `${min}` || '';
    }
    if (min !== undefined && num < min) {
      return min.toString();
    }
    if (max !== undefined && num > max) {
      return max.toString();
    }
  }
  return value;
};

const applyTimeBlur = (value: string) => {
  const time = value.replace(/[^0-9:]/g, '');

  const extractHoursMins = (value: string) => {
    if (value.indexOf(':') === -1 && value.length > 2) {
      switch (value.length) {
        case 3:
          return [value.slice(0, 1), value.slice(1)];
        default:
          return [value.slice(0, 2), value.slice(2)];
      }
    } else {
      return value.split(':');
    }
  };

  const [hours, minutes] = extractHoursMins(time);
  const hoursInt = parseInt(hours);
  const minutesInt = parseInt(minutes);
  const h = isNaN(hoursInt) ? 0 : Math.min(23, hoursInt);
  const m = isNaN(minutesInt) ? 0 : Math.min(59, minutesInt);
  return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}`;
};

const applyDateBlur = (value: string) => {
  if (value.length < 3) {
    return '';
  }
  let [day, month, year] = value.split(/\.|-|\//).map(Number);
  if (!month) {
    day = parseInt(value.substring(0, 2));
    month = parseInt(value.substring(2));
  }
  day = isNaN(day) ? 1 : Math.min(31, Math.max(1, day));
  month = isNaN(month) ? 1 : Math.min(12, Math.max(1, month));
  if (year < 100) {
    year += 2000;
  }
  year = isNaN(year)
    ? new Date().getFullYear()
    : Math.min(9999, Math.max(1900, year));

  let date = new Date(year, month - 1, day);
  if (date.getMonth() !== month - 1) {
    date = new Date(year, month - 1, day - date.getDate());
  }

  return date.toLocaleDateString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  });
};

const applyDayBlur = (value: string) => {
  value = applyDateBlur(value);
  return value.substring(0, 5); //drop year
};

const applyPhoneBlur = (value: string) => {
  value = value.replace(/\s/g, ''); //remove all whitespaces

  const extractCountryCode = (value: string) => {
    if (value[0] === '+') {
      return { value: value.substring(1, 3), offset: 3 };
    } else if (value.startsWith('00')) {
      return { value: value.substring(2, 4), offset: 4 };
    }
    return { value: undefined, offset: 0 };
  };

  const extractTrunkCode = (value: string, offset: number) => {
    value = value.substring(offset);
    for (const cityCode of shortCityCodes) {
      if (value.startsWith(cityCode)) {
        return {
          value: value.substring(0, cityCode.length),
          offset: offset + cityCode.length
        };
      }
    }
    return { value: value.substring(0, 4), offset: offset + 4 };
  };

  const country = extractCountryCode(value);
  const trunk = extractTrunkCode(value, country.offset);
  const number = value
    .substring(trunk.offset)
    .match(/.{1,4}/g)
    ?.join(' ');
  return country.value
    ? `+${country.value} ${trunk.value} ${number || ''}`
    : `${trunk.value} ${number || ''}`;
};
