import { ClientBackendContext } from '@kidsmanager/ui-api';
import { Button, DateRangePicker } from '@kidsmanager/ui-core';
import { DateRange } from 'libs/ui-core/src/date-picker/date-types';
import { useContext, useEffect, useState } from 'react';

export interface HolidayNewProps {
  userId?: string;
  onRequestMade?: () => void;
}

export const HolidayNew = (props: HolidayNewProps) => {
  const [publicHols, setPublicHols] = useState<Date[]>([]);
  const [range, setRange] = useState<DateRange>();
  const [requiredDays, setRequiredDays] = useState(0);

  const client = useContext(ClientBackendContext);
  useEffect(() => {
    const year = new Date().getFullYear();
    client.holiday.publicHolidays(year - 1, year, year + 1).then(setPublicHols);
  }, [client]);

  const onRangeChange = (from?: Date, to?: Date) => {
    setRange({ from, to });
    if (!from) {
      setRequiredDays(0);
    } else if (from && !to) {
      setRequiredDays(1);
    } else if (from && to) {
      setRequiredDays(from.workingDaysUntil(to, publicHols));
    }
  };

  const onRequestHoliday = async () => {
    if (!range || !range.from) {
      return;
    }
    await client.holiday.request(range.from, range.to, props.userId);
    props.onRequestMade?.();
  };

  return (
    <div className="mx-auto my-5 max-w-lg">
      <DateRangePicker
        start={new Date()}
        onChange={onRangeChange.bind(this)}
        publicHolidays={publicHols}
      />
      <div className="my-8 text-center">
        <div className="my-2 text-sm text-black/50">
          {`${requiredDays === 0 ? '-' : requiredDays} Urlaubstag${requiredDays === 1 ? '' : 'e'}`}
        </div>
        <Button
          disabled={requiredDays === 0}
          color="primary"
          onClick={onRequestHoliday.bind(this)}
        >
          <span className="px-4 uppercase leading-8">beantragen</span>
        </Button>
      </div>
    </div>
  );
};
