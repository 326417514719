import logo_zeitmgr from './zietmgr.svg';

const getColor = (
  mode: 'color' | 'black' | 'white' | undefined,
  color: string
) => {
  switch (mode) {
    case 'black':
      return 'bg-black';
    case 'white':
      return 'bg-white';
    default:
      return color;
  }
};

/**
 * The classic KidsManager logo in various formats.
 * Generate the full logo or just the icon, in full color, black or white.
 * @param props
 * @returns
 */
export const Logo = (props: {
  brand?: 'kidsmgr' | 'zeitmgr';
  format?: 'icon' | 'full';
  size?: number;
  color?: 'color' | 'black' | 'white';
}) => {
  const size = props.size || 40;
  if (props.brand === 'zeitmgr') {
    return <img width={size} height={size} src={logo_zeitmgr} alt="logo" />;
  } else {
    return props.format === 'icon' ? (
      <div
        className="m-1 inline-grid grid-cols-2 gap-[2px]"
        style={{ width: size, height: size }}
      >
        <div className={getColor(props.color, 'bg-km-green')} />
        <div className={getColor(props.color, 'bg-km-orange')} />
        <div className={getColor(props.color, 'bg-km-blue')} />
        <div className={getColor(props.color, 'bg-km-pink')} />
      </div>
    ) : (
      <div className="inline-block py-2">
        <div className="pb-2 text-center text-5xl">
          <span className="font-bold">Kids</span>Manager
        </div>
        <div className="flex h-12 w-full justify-center gap-2">
          <div className="bg-km-green w-14" />
          <div className="bg-km-orange w-14" />
          <div className="bg-km-blue w-14" />
          <div className="bg-km-pink w-14" />
        </div>
      </div>
    );
  }
};
