import { useMemo } from 'react';
import { determineTextColor } from '../helper';

export interface BadgeProps {
  name: string;
  color: string;
  symbol?: string;
  count?: number;
  size?: 'sm' | 'md';
  infoOnly?: boolean;
  onClick?: (e: React.MouseEvent) => void;
}

export const Badge = (props: BadgeProps) => {
  const textColor = useMemo(
    () => determineTextColor(props.color),
    [props.color]
  );

  return (
    <div
      className={`${props.size === 'sm' ? 'w-6 text-xs leading-6' : 'w-8'} rounded-full text-center`}
      style={{
        opacity: props.infoOnly ? 0.8 : 1,
        backgroundColor: props.color,
        color: textColor,
        userSelect: 'none'
      }}
      onClick={props.onClick}
    >
      {props.symbol || '?'}
    </div>
  );
};
