export interface DialogMobileContainerProps {
  close?: () => void;
  children: React.ReactNode;
}

export const DialogMobileContainer = (props: DialogMobileContainerProps) => {
  return (
    <div
      className="fixed bottom-0 left-0 right-0 top-0 z-40 bg-black/60"
      onClick={props.close?.bind(this)}
    >
      <div className="animate-slideUp absolute bottom-0 left-0 right-0 justify-center rounded-t-xl bg-white">
        <div onClick={(e) => e.stopPropagation()}>{props.children}</div>
      </div>
    </div>
  );
};
