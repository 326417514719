import '@kidsmanager/util-extensions';

export class ContractedProRata<T> {
  changes: { from: Date; value: number }[] = [];

  value = 0.0;
  days = 0.0;

  constructor(
    changes: T[],
    valueField: keyof T,
    dateField: keyof T = 'from' as keyof T
  ) {
    this.changes = changes.map((x) => ({
      from: ContractedProRata.parseUtcDate(x[dateField] as string),
      value: x[valueField] as number
    }));
  }

  forYear(
    year: number,
    ignoreDates: (Date | string)[] = []
  ): { weekly: number; monthly: number } {
    const ignore = ContractedProRata.parseUtcDates(ignoreDates);
    const first = new Date(Date.UTC(year, 0, 1));
    const last = new Date(Date.UTC(year + 1, 0, 1));

    const sorted: { from: Date; perDay: number }[] = [];
    this.changes.forEach((value) => {
      sorted.push({
        from: ContractedProRata.parseUtcDate(value.from),
        perDay: value.value / 5.0
      });
    });
    sorted.sort((a, b) => a.from.getTime() - b.from.getTime());

    if (sorted.length > 0 && sorted[0].from > first) {
      //user started after start of year, but pretend they start with
      //beginning of year for calculation purpose
      sorted[0].from = first;
    }

    let workingDays = 0;
    let workingHours = 0.0;
    for (let i = 0; i < sorted.length; i++) {
      if (sorted[i].from > last) {
        continue;
      }
      if (sorted.length > i + 1 && sorted[i + 1].from <= first) {
        continue;
      }

      const from = sorted[i].from > first ? sorted[i].from : first;
      const until = sorted.length > i + 1 ? sorted[i + 1].from : last;
      const weekdays = ContractedProRata.weekDaysBetween(from, until);
      const ignoredays = ContractedProRata.weekDaysContained(
        from,
        until,
        ignore
      );

      const days = weekdays - ignoredays;
      console.log(from.toLocaleISODate(), days);
      workingHours += days * sorted[i].perDay;
      workingDays += days;
    }
    return {
      weekly: (workingHours / workingDays) * 5,
      monthly: workingHours / 12
    };
  }

  static weekDaysBetween(from: Date, to: Date): number {
    let count = 0;
    const start = new Date(from);
    while (start < to) {
      if (start.getDay() !== 0 && start.getDay() !== 6) {
        count++;
      }
      start.setDate(start.getDate() + 1);
    }
    return count;
  }

  static weekDaysContained(from: Date, to: Date, dates: Date[]): number {
    return dates.reduce((acc, date) => {
      if (date.getDay() === 0) return acc;
      if (date.getDay() === 6) return acc;
      return date >= from && date <= to ? acc + 1 : acc;
    }, 0);
  }

  static parseUtcDate = (value: string | Date): Date => {
    //HACK: stored as Date or string; in future will be "yyyy-MM-dd"
    //at which point we can clean up this function
    if (typeof value === 'string') {
      const [year, month, day] = value
        .split(/[-.]/)
        .map((x) => parseInt(x, 10));
      return new Date(Date.UTC(year, month - 1, day));
    } else {
      return new Date(value);
    }
  };

  static parseUtcDates = (values: (Date | string)[]): Date[] => {
    return values.map((x) => {
      if (x instanceof Date) {
        //HACK: this is currently needed because the incoming dates
        //for public holidays in the code are just parsed locally and
        //not converted to UTC
        return ContractedProRata.parseUtcDate(x.toLocaleISODate());
      } else {
        return ContractedProRata.parseUtcDate(x);
      }
    });
  };
}
