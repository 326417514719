import { ISaveEntry } from '@kidsmanager/util-models';

export interface AbsenceOption {
  label: string;
  tag: string;
  code: string;
  infoOnly: boolean;
}

export interface INonWorkEntry {
  from: Date;
  entry: ISaveEntry;
}

export const parseOutMins = (time: string) => {
  const [hours, mins] = time.split(':').map((v) => parseInt(v));
  return hours * 60 + mins;
};

const createEntry = (option: AbsenceOption, duration: number) => {
  return {
    shiftId: '',
    note: '',
    label: option.label,
    duration: duration * 3600,
    breakDuration: 0,
    index: 0,
    total: 1,
    tag: option.tag ? option.tag : 'NLZ',
    type: 'non-work',
    code: option.code,
    infoOnly: option.infoOnly
  };
};

export const absenceExpand = (
  from: Date,
  to: Date,
  startTime: string,
  endTime: string,
  hoursPerDay: number,
  publicHols: Date[],
  option: AbsenceOption
): INonWorkEntry[] => {
  const entries: INonWorkEntry[] = [];
  const days = from.workingDaysUntil(to, publicHols);
  if (days < 2) {
    const duration = (parseOutMins(endTime) - parseOutMins(startTime)) / 60;
    entries.push({
      from: new Date(from.getTime() + parseOutMins(startTime) * 60 * 1000),
      entry: createEntry(option, duration)
    });
  } else {
    const day = new Date(from.getFullYear(), from.getMonth(), from.getDate());
    while (day <= to) {
      if (!day.isWeekend()) {
        entries.push({
          from: new Date(day.getTime() + 9 * 3600 * 1000),
          entry: createEntry(option, hoursPerDay)
        });
      }

      day.setDate(day.getDate() + 1);
    }
  }
  entries.forEach((e, i) => {
    e.entry.index = i;
    e.entry.total = entries.length;
  });

  return entries;
};
