import {
  Button,
  Dialog,
  DialogContext,
  LoadSpinner,
  Revealer,
  Textarea,
  ToggleSplitButton
} from '@kidsmanager/ui-core';
import { ISaveAs } from '@kidsmanager/util-models';
import { useContext, useMemo, useState } from 'react';
import { SaveOptionsDialog } from './save-options-dialog';

export interface ShiftSaveAsProps {
  start: Date;
  end: Date;
  options?: ISaveAs[];
  saving: boolean;
  onSaveAs?: (option?: ISaveAs, explanation?: string) => void;
}

export const ShiftSaveAs = (props: ShiftSaveAsProps) => {
  const options = props.options || [];
  const dialog = useContext(DialogContext);
  const [needsExplanation, setsNeedsExplanation] = useState(false);
  const [explanation, setExplanation] = useState<string>();

  const duration = useMemo(() => {
    const hours = (props.end.getTime() - props.start.getTime()) / 3600000;
    setsNeedsExplanation(hours > 25);
    return hours;
  }, [props.start, props.end]);

  const openSaveAs = () => {
    dialog.open(
      <Dialog title="Speichern als..." close={dialog.close.bind(dialog)}>
        <SaveOptionsDialog
          options={props.options}
          onClose={(value) => {
            const option = props.options?.find(
              (o) => o.name.toLowerCase() === value?.toLowerCase()
            );
            props.onSaveAs?.(option, explanation);
            dialog.close();
          }}
        />
      </Dialog>
    );
  };

  const onOptionSelected = (value?: string) => {
    const option = value
      ? props.options?.find((o) => o.name.toLowerCase() === value.toLowerCase())
      : undefined;
    props.onSaveAs?.(option, explanation);
  };

  const handleExplanationChange = (value: string) => {
    setExplanation(value);
    setsNeedsExplanation(value.length === 0);
  };

  return (
    <div className="text-center">
      <div className="mb-1 text-center">
        <span
          className={`${duration > 25 ? 'text-orange-400' : 'text-black/50'}`}
        >
          {duration.toFixed(2)} Stunden
        </span>
        <Revealer show={duration > 25}>
          <div className="mb-4 flex flex-col gap-2">
            <p className="text-sm text-orange-400">
              Über 25 Stunden erfordert eine zusätzliche Genehmigung
            </p>
            <Textarea
              rows={3}
              placeholder="Erklärung für Ihren Vorgesetzten"
              value={explanation}
              onChange={handleExplanationChange.bind(this)}
            />
            <p className="text-sm text-black/40">
              E-Mail wird beim Speichern an den Vorgesetzten gesendet
            </p>
          </div>
        </Revealer>
      </div>

      <LoadSpinner show={props.saving}>
        {options.length > 0 && (
          <ToggleSplitButton
            color="primary"
            disabled={props.saving || needsExplanation}
            onExpandOptions={openSaveAs.bind(this)}
            onSelect={onOptionSelected.bind(this)}
          >
            <span className="inline-block p-2 uppercase tracking-widest">
              Spiechern
            </span>
          </ToggleSplitButton>
        )}
        {options.length <= 0 && (
          <Button
            color="primary"
            onClick={props.onSaveAs?.bind(this, undefined)}
            disabled={props.saving || needsExplanation}
          >
            <span className="inline-block p-2 uppercase tracking-widest">
              Spiechern
            </span>
          </Button>
        )}
      </LoadSpinner>
    </div>
  );
};
