import { Period48 } from '@kidsmanager/util-common';
import { IShift } from '@kidsmanager/util-models';

export interface IShiftPart {
  label: string;
  infoOnly: boolean;
  from: Date;
  until: Date;
  breakHours: number;
  index: number;
  total: number;
}

export const expandShift = (
  shift: IShift | undefined,
  from: Date,
  until: Date,
  subshifts: Period48[],
  breakHours: number,
  infoOnly: boolean
): IShiftPart[] => {
  if (!shift || !from || !until) {
    return [];
  }

  const parts: IShiftPart[] = [];

  addMainShift(shift.name, from, until, subshifts, parts);
  addSubShifts(from, subshifts, parts);
  splitMidnightSpans(parts);

  //apply index to each part
  parts.forEach((part, index) => {
    part.infoOnly = part.infoOnly || infoOnly;
    part.index = index;
    part.total = parts.length;
  });

  //set break hours on the first shiftPart
  if (shift.nominalBreakHours > 0 && parts.length > 0) {
    parts[0].breakHours = breakHours;
  }

  return parts;
};

const addMainShift = (
  name: string,
  from: Date,
  until: Date,
  subshifts: Period48[],
  parts: IShiftPart[]
): void => {
  if (subshifts.length === 0) {
    parts.push(createShiftPart(name, from, until));
  } else {
    const subshiftsStart = subshifts.reduce((lowest, period) => {
      return period.start < lowest ? period.start : lowest;
    }, Infinity);
    const subshiftsEnd = subshifts.reduce((highest, period) => {
      return period.end > highest ? period.end : highest;
    }, -Infinity);
    const day = new Date(from.getFullYear(), from.getMonth(), from.getDate());
    const firstEnd = new Date(day.getTime() + subshiftsStart * 1000);
    parts.push(createShiftPart(name, from, firstEnd));
    const secondStart = new Date(day.getTime() + subshiftsEnd * 1000);
    parts.push(createShiftPart(name, secondStart, until));
  }
};

const addSubShifts = (
  from: Date,
  subshifts: Period48[],
  parts: IShiftPart[]
): void => {
  const day = new Date(from.getFullYear(), from.getMonth(), from.getDate());
  for (const period of subshifts) {
    const label =
      period.type === 'oncall' ? 'Nachtbereitschaft' : 'Nachtdienst';
    const infoOnly = period.type === 'oncall';
    const from = new Date(day.getTime() + period.start * 1000);
    const until = new Date(day.getTime() + period.end * 1000);
    parts.push(createShiftPart(label, from, until, infoOnly));
  }

  parts.sort((a, b) => (a.from > b.from ? 1 : -1));
};

const splitMidnightSpans = (shiftParts: IShiftPart[]): void => {
  shiftParts.forEach((part) => {
    if (part.from.getDay() === part.until.getDay()) {
      return;
    }

    const nextDay = new Date(
      part.until.getFullYear(),
      part.until.getMonth(),
      part.until.getDate()
    );
    const afterMidnight = part.until.getTime() - nextDay.getTime();
    if (afterMidnight === 0) {
      return;
    }

    part.until = new Date(nextDay.getTime());

    const split = createShiftPart(
      part.label,
      nextDay,
      new Date(nextDay.getTime() + afterMidnight),
      part.infoOnly
    );

    shiftParts.push(split);
  });

  //sort by from date and then by label
  shiftParts.sort((a, b) => {
    if (a.from.getTime() === b.from.getTime()) {
      return a.label > b.label ? 1 : -1;
    }
    return a.from > b.from ? 1 : -1;
  });
};

const createShiftPart = (
  label: string,
  from: Date,
  until: Date,
  infoOnly = false
): IShiftPart => {
  return {
    label,
    from,
    until,
    infoOnly,
    breakHours: 0,
    index: 0,
    total: 0
  };
};
