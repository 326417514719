export const PageNotFound = () => {
  return (
    <div className="mx-auto mt-40 max-w-md text-center">
      <h1 className="text-2xl">404 - Seite nicht gefunden</h1>
      <p>
        Die Seite, die du suchst, existiert nicht. Überprüfe die URL oder kehre
        zur Startseite zurück.
      </p>
    </div>
  );
};
