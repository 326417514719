import React from 'react';
import { ClientBackend } from './client-backend';
import { AuthState, IAuth } from './auth';

export const ClientBackendContext = React.createContext(
  new ClientBackend({} as IAuth, (state) => state)
);

export interface ClientBackendProviderProps {
  children: React.ReactNode;
  auth: IAuth;
  handleRefresh: (state: AuthState) => AuthState;
}

export function ClientBackendProvider(props: ClientBackendProviderProps) {
  const client = new ClientBackend(props.auth, props.handleRefresh);
  return (
    <ClientBackendContext.Provider value={client}>
      {props.children}
    </ClientBackendContext.Provider>
  );
}
