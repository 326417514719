import { Button } from '@kidsmanager/ui-core';
import { ISaveAs } from '@kidsmanager/util-models';
import { useMemo } from 'react';

export interface SaveOptionsDialogProps {
  options?: ISaveAs[];
  onClose: (option: string | undefined) => void;
}

export const SaveOptionsDialog = (props: SaveOptionsDialogProps) => {
  const options: ISaveAs[] = useMemo(
    () => [
      { name: 'Normal', description: '', abbr: '', infoOnly: false },
      ...(props.options || [])
    ],
    [props.options]
  );

  const descriptionL1 = (desc: string): string => {
    if (desc.trim().endsWith(')') && desc.indexOf('(') > 0) {
      return desc.substring(0, desc.indexOf('('));
    } else {
      return desc;
    }
  };

  const descriptionL2 = (desc: string): string => {
    if (desc.trim().endsWith(')') && desc.indexOf('(') > 0) {
      return desc.substring(desc.indexOf('('));
    } else {
      return '';
    }
  };

  return (
    <ul className="mx-auto">
      {options?.map((option, index) => (
        <li key={index} className="my-2">
          <Button
            className="w-full"
            color="white"
            onClick={props.onClose.bind(
              this,
              index > 0 ? option.name : undefined
            )}
          >
            <div className="flex min-h-20 flex-col items-center justify-center md:w-[440px]">
              <div className="text-lg font-medium uppercase">{option.name}</div>
              <div className="font-light text-black/70">
                {descriptionL1(option.description)}
              </div>
              <div className="font-light text-black/70">
                {descriptionL2(option.description)}
              </div>
            </div>
          </Button>
        </li>
      ))}
    </ul>
  );
};
