/**
 * A period defined as start/end in seconds over a 48hr duration
 */
export interface Period48 {
  start: number;
  end: number;
  type: 'night' | 'oncall';
}

export interface Period24 {
  start: number;
  end: number;
}

/**
 * Create a Period48 object from a start and end times
 * @param start 'HH:mm'where HH is 00-48 and mm is 00-59, if HH prefixed with * then it is the next day
 * @param end 'HH:mm' where HH is 00-48 and mm is 00-59, if HH prefixed with * then it is the next day
 * @param type 'night' | 'oncall'
 * @returns
 */
export const createPeriod48 = (
  start: string,
  end: string,
  type: 'night' | 'oncall'
): Period48 => {
  const getHours = (value: string): number => {
    if (value.startsWith('*')) {
      return Number(value.substring(1)) + 24;
    } else {
      return Number(value);
    }
  };

  const [startHour, startMinute] = start.split(':');
  const [endHour, endMinute] = end.split(':');

  const startSeconds = getHours(startHour) * 3600 + Number(startMinute) * 60;
  const endSeconds = getHours(endHour) * 3600 + Number(endMinute) * 60;

  if (startSeconds < 0 || startSeconds >= 48 * 3600) {
    throw new Error('Invalid start time');
  }

  if (endSeconds < 0 || endSeconds >= 48 * 3600) {
    throw new Error('Invalid end time');
  }

  if (startSeconds >= endSeconds) {
    throw new Error('Start time must be before end time');
  }

  return {
    start: startSeconds,
    end: endSeconds,
    type: type
  };
};

export const createPeriod24 = (
  date: string,
  start: string,
  end: string
): Period24 => {
  const offset = new Date(date).getTimezoneOffset() * 60;
  const [startHour, startMinute] = start.split(':');
  const [endHour, endMinute] = end.split(':');

  const startSeconds =
    Number(startHour) * 3600 + Number(startMinute) * 60 + offset;
  const endSeconds = Number(endHour) * 3600 + Number(endMinute) * 60 + offset;

  return { start: startSeconds, end: endSeconds };
};
