import {
  IHolidayBooking,
  IMonth,
  IReleasedMemberPlan,
  IShiftSpec
} from '@kidsmanager/util-models';
import { createSpecLookup } from './spec-lookup';
import { DayValues } from '../compoenents/month-calendar-view';
import { DayValueType } from '../compoenents/month-day-value';

export type Filter = 'both' | 'logged' | 'planned';

export const compileView = (
  date: Date,
  bookings: IHolidayBooking[],
  publicHolidays: Date[],
  specs: IShiftSpec[],
  plan: IReleasedMemberPlan,
  logged: IMonth,
  filter: Filter
): DayValues[] => {
  const days: DayValues[] = Array.from(
    { length: logged.days.length },
    (_, i) => {
      const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), i + 1));
      if (publicHolidays.find((h) => h.dateMatches(d))) {
        return { values: [{ label: 'FT', type: 'other' }] };
      }
      if (bookings.find((h) => d >= h.from && d <= h.to)) {
        return { values: [{ label: 'UT', type: 'other' }] };
      }
      return { values: [] };
    }
  );
  if (filter === 'both' || filter === 'logged') {
    days.forEach((d, i) => {
      if (d.values.length === 0) {
        days[i] = { values: [{ label: logged.days[i], type: 'logged' }] };
      }
    });
  }
  if (filter === 'both' || filter === 'planned') {
    const today = new Date();
    const lookup = createSpecLookup(specs);

    days.forEach((_, i) => {
      const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), i + 1));
      if (d < today) {
        return;
      }

      if (i >= plan.assigned.length || plan.assigned[i].shifts.length === 0) {
        return;
      }
      const values = plan.assigned[i].shifts.map((s) => ({
        label: lookup.get(s)?.symbol || '?',
        type: 'planned' as DayValueType
      }));
      days[i] = { values };
    });
  }

  return days;
};

export const workingDays = (date: Date, publicHols: Date[]): number => {
  const days = Array.from({
    length: new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
  })
    .map((_, i) => new Date(date.getFullYear(), date.getMonth(), i + 1))
    .filter((day) => {
      if (day.isWeekend()) {
        return false;
      }
      if (publicHols.find((h) => h.dateMatches(day))) {
        return false;
      }
      return true;
    });
  return days.length;
};
