import { ClientBackendContext } from '@kidsmanager/ui-api';
import { useContext, useEffect } from 'react';
import { unlinkWithLegacy } from '../helpers/linking';

export interface FeatureLogoutProps {
  unlinkWithLegacy?: boolean;
}

export const FeatureLogout = (props: FeatureLogoutProps) => {
  const client = useContext(ClientBackendContext);
  useEffect(() => {
    const tenant = localStorage.getItem('tenant');
    if (tenant && tenant !== 'dev' && props.unlinkWithLegacy) {
      unlinkWithLegacy(tenant, () => {
        client.auth.logout();
        window.location.href = '/';
      });
    } else {
      client.auth.logout();
      window.location.href = '/';
    }
  }, [client, props]);
  return <div></div>;
};
