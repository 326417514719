import { useState, useEffect } from 'react';

export const useMediaQuery = (query = '(max-width: 500px)') => {
  const [matches, setMatches] = useState(window?.matchMedia?.(query).matches);
  useEffect(() => {
    const listener = () => setMatches(window.matchMedia(query).matches);
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  });
  return matches;
};
export default useMediaQuery;
