import { Link } from 'react-router-dom';
import { Logo } from '../brand/logo';

export interface DrawerNavItem {
  label: string;
  path: string;
  root?: boolean;
  icon?: string;
  breakBefore?: boolean;
}

export interface DrawerNavProps {
  items: DrawerNavItem[];
  brand: 'zeitmgr' | 'kidsmgr';
  displayName: string;
}

const NavItem = (props: DrawerNavItem) => {
  const selected =
    window.location.pathname.startsWith(props.path) ||
    (props.root && window.location.pathname.length <= 1);

  return (
    <>
      {props.breakBefore && <hr />}
      <li
        className={`hover:bg-neutral-100 active:bg-neutral-200 ${selected ? 'text-primary' : ''}`}
      >
        <Link to={props.path} className="outline-focus flex items-center py-4">
          {props.icon && (
            <span className="material-icons mx-4">{props.icon}</span>
          )}
          <span className="opacity-60">{props.label}</span>
        </Link>
      </li>
    </>
  );
};

export const DrawerNav = (props: DrawerNavProps) => {
  return (
    <nav>
      <div className="border-b border-neutral-200 p-4">
        <Logo brand={props.brand} format="icon" size={48} />
        <h2 className="mt-1 text-lg font-medium">{props.displayName}</h2>
      </div>
      <ul className="p-0.5 pl-1">
        {props.items.map((item, index) => (
          <NavItem key={index} {...item} />
        ))}
      </ul>
    </nav>
  );
};
