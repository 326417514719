import { symbolize } from '@kidsmanager/util-common';
import { IShiftSpec } from '@kidsmanager/util-models';

export const createSpecLookup = (spec: IShiftSpec[]) => {
  symbolize(spec.sort((a, b) => (a.start > b.start ? 1 : -1)));

  const lookup = new Map<string, { name: string; symbol?: string }>();
  spec.forEach((s) => {
    lookup.set(s.id, { name: s.name, symbol: s.symbol });
  });
  return lookup;
};
