export type DateRange = {
  from?: Date;
  to?: Date;
};

export type CalendarMonth = {
  offset: number;
  month: Date;
  weeks: CalendarWeek[];
};

export type CalendarDay = {
  label: string;
  date: Date | undefined;
  publicHoliday?: boolean;
  selected?: boolean;
};

export type CalendarWeek = {
  week: number;
  start: Date;
  end: Date;
  days: CalendarDay[];
};

export const setDaySelected = (
  day: CalendarDay | undefined,
  value: boolean
) => {
  if (!day) return;
  day.selected = value;
};

export const findDay = (months: CalendarMonth[], date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth();

  const matchingMonth = months.find(
    (m) => m.month.getFullYear() === year && m.month.getMonth() === month
  );

  return matchingMonth?.weeks
    .flatMap((w) => w.days.find((d) => d.date === date))
    .filter((d) => !!d)[0];
};
