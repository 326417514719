import { ClientBackendContext } from '@kidsmanager/ui-api';
import {
  UserPicker,
  UserPickerGroup,
  UserPickerUser
} from '@kidsmanager/ui-core';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export interface TimesheetLowerHeaderProps {
  children?: React.ReactNode;
  onUserSelected?: (userId: string) => void;
}

const formatUsername = (lastName?: string, firstName?: string): string => {
  if (!firstName) {
    return lastName || '';
  }
  return `${lastName}, ${firstName}`;
};

export const TimesheetLowerHeader = (props: TimesheetLowerHeaderProps) => {
  const [user, setUser] = useState<UserPickerUser>();
  const [groups, setGroups] = useState<UserPickerGroup[]>([]);
  const client = useContext(ClientBackendContext);
  const params = useParams<{ userId: string }>();

  useEffect(() => {
    client.user.byPrimaryGroup().then((groups) => {
      const selectedUserId = params.userId === 'my' ? '' : params.userId;
      const selectedUser = groups.flatMap((g) =>
        g.users.filter((u) => u.userId === selectedUserId)
      )[0];
      if (selectedUser) {
        setUser({
          userId: selectedUser.userId,
          name: formatUsername(selectedUser.lastName, selectedUser.firstName)
        });
      }
      setGroups(
        groups.map((g) => ({
          name: g.name,
          users: g.users.map((user) => ({
            userId: user.userId || '',
            name: formatUsername(user.lastName, user.firstName)
          }))
        }))
      );
    });
  }, [client, params.userId]);

  const onUserSelected = (user: UserPickerUser) => {
    setUser(user);
    props.onUserSelected?.(user.userId || 'my');
    (document.activeElement as HTMLElement)?.blur();
  };

  return (
    <div
      className={`border-silver-100 relative -m-2 mb-1 border-b ${params.userId === 'my' ? 'bg-neutral-100' : 'bg-yellow-50'} ${groups.length > 0 ? 'h-36' : 'h-32'}`}
    >
      <div className="pt-7">
        {groups.length > 0 && (
          <UserPicker
            selected={user}
            groups={groups}
            onSelected={onUserSelected.bind(this)}
          />
        )}
      </div>
      <div
        className="absolute inset-x-0 grid flex-1 grid-cols-2 items-center"
        style={{ bottom: groups.length > 0 ? '20px' : '35px' }}
      >
        {props.children}
      </div>
    </div>
  );
};
