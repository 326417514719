import {
  IMemberAssigments,
  IReleasedMemberPlan,
  IReleasedPlan
} from '@kidsmanager/util-models';
import { IAuth, fetch } from '../auth';
import { BackendCache } from '../backend-cache';

const cache = new BackendCache();

export class ApiRosterPlan {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {}

  async find(
    year: number,
    month: number,
    members: string[]
  ): Promise<IMemberAssigments[]> {
    if (!members.length) {
      return [];
    }

    const iso_month = new Date(year, month - 1).toLocaleISOMonth();
    return cache.get<IMemberAssigments[]>(
      `plan-${iso_month}-${members.join('-')}`,
      async () => {
        return (
          await this.fetch(
            `api/roster/plan/version/draft?month=${iso_month}${members.map((member) => `&id=${member}`).join('')}`
          )
        ).json();
      }
    );
  }

  async update(year: number, month: number, plan: IMemberAssigments[]) {
    cache.clearAll();
    const iso_month = new Date(year, month - 1).toLocaleISOMonth();
    await this.fetch(`api/roster/plan/version/${iso_month}`, {
      method: 'PUT',
      body: JSON.stringify(plan)
    });
  }

  async releases(groupId: string, year: number, month: number) {
    const iso_month = new Date(year, month - 1).toLocaleISOMonth();
    return cache.get<IReleasedPlan[]>(
      `releases-${iso_month}-${groupId}`,
      async () => {
        return (
          await this.fetch(
            `api/roster/plan/release?month=${iso_month}&group=${groupId}`
          )
        ).json();
      }
    );
  }

  async released(
    year: number,
    month: number,
    userId?: string
  ): Promise<IReleasedMemberPlan> {
    userId = userId || 'my';
    const iso_month = new Date(year, month - 1).toLocaleISOMonth();
    return cache.get<IReleasedMemberPlan>(
      `released-${iso_month}-${userId}`,
      async () => {
        return (
          await this.fetch(
            `api/roster/plan/member/release?month=${iso_month}&id=${userId}`
          )
        ).json();
      }
    );
  }

  async release(
    groupId: string,
    year: number,
    month: number,
    members: string[]
  ): Promise<IReleasedPlan> {
    const iso_month = new Date(year, month - 1).toLocaleISOMonth();
    cache.clear(`releases-${iso_month}-${groupId}`);
    return (
      await this.fetch(
        `api/roster/plan/release?month=${iso_month}&group=${groupId}${members.map((member) => `&id=${member}`).join('')}`,
        {
          method: 'POST'
        }
      )
    ).json();
  }

  async unpublish(
    groupId: string,
    year: number,
    month: number,
    version: string,
    members: string[]
  ): Promise<IReleasedPlan> {
    const iso_month = new Date(year, month - 1).toLocaleISOMonth();
    cache.clear(`releases-${iso_month}-${groupId}`);
    return (
      await this.fetch(
        `api/roster/plan/release/${version}?month=${iso_month}&group=${groupId}${members.map((member) => `&id=${member}`).join('')}`,
        {
          method: 'DELETE'
        }
      )
    ).json();
  }

  async discard(
    groupId: string,
    year: number,
    month: number,
    version: string,
    members: string[]
  ): Promise<IReleasedPlan> {
    const iso_month = new Date(year, month - 1).toLocaleISOMonth();
    cache.clear(`releases-${iso_month}-${groupId}`);
    cache.clear(`plan-${iso_month}-${members.join('-')}`);
    return (
      await this.fetch(
        `api/roster/plan/version/${version}?month=${iso_month}&group=${groupId}${members.map((member) => `&id=${member}`).join('')}`,
        {
          method: 'DELETE'
        }
      )
    ).json();
  }
}
