export interface ButtonProps {
  children: React.ReactNode;
  disabled?: boolean;
  selected?: boolean;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  color?: 'primary' | 'default' | 'white';
  onClick?: () => void;
}

/**
 * Should be used for all standard buttons in the application, to ensure a consistent look and feel.
 */
export const Button = (props: ButtonProps) => {
  const color = props.color || 'default';

  return props.disabled ? (
    <button
      disabled={true}
      className={`border-silver-200 bg-silver-100 text-silver-950/25 rounded-md border px-2 py-1 text-sm ${props.className}`}
    >
      {props.children}
    </button>
  ) : (
    <button
      type={props.type}
      onClick={props.onClick}
      className={
        `border-silver-400 outline-focus rounded-md border px-2 py-1 text-sm outline-offset-4 ${props.className} ` +
        (!props.selected
          ? color === 'primary'
            ? 'bg-primary text-contrast'
            : `text-silver-950 hover:bg-silver-200 active:bg-silver-500 ${color === 'white' ? 'bg-white' : 'bg-neutral-200'}`
          : 'bg-lime-500 text-white active:bg-lime-300')
      }
    >
      {props.children}
    </button>
  );
};
