export interface GhostButtonProps {
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
}

export const GhostButton = (props: GhostButtonProps) => {
  return props.disabled ? (
    <button
      disabled={true}
      className={`border-silver-200 text-silver-950/25 rounded-md border px-2 py-1 text-sm ${props.className}`}
    >
      {props.children}
    </button>
  ) : (
    <button
      type={props.type}
      onClick={props.onClick}
      className={`outline-focus rounded-md border border-transparent px-2 py-1 text-sm outline-offset-4 hover:border-neutral-100 hover:bg-neutral-100 ${props.className}`}
    >
      {props.children}
    </button>
  );
};
