import { Button } from '@kidsmanager/ui-core';
import { useEffect, useState } from 'react';

export const PwaRemove = (): React.ReactNode => {
  const [registrations, setRegistrations] = useState(0);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        setRegistrations(registrations.length);
      });
    }
  });

  const remove = async () => {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (const registration of registrations) {
        registration.unregister();
      }
    });
  };

  return (
    <div className="p-10">
      <h1>ZeitManager - Old Version Checker</h1>
      <p className="mb-2 mt-1">Service Workers: {registrations}</p>
      {registrations > 0 && (
        <Button onClick={remove}>Remove registrations</Button>
      )}
    </div>
  );
};
