export const symbolize = (
  items: { name: string; symbol?: string; index?: number }[]
) => {
  const totals: { [key: string]: number } = {};

  items.sort((a, b) => ((a.index || a.name) > (b.index || b.name) ? 1 : -1));
  items.forEach((shift) => {
    const firstChar = shift.name.at(0)?.toUpperCase() || '';
    totals[firstChar] = (totals[firstChar] || 0) + 1;
  });

  const counts: { [key: string]: number } = {};
  items.forEach((shift) => {
    const firstChar = shift.name.at(0)?.toUpperCase() || '';
    counts[firstChar] = (counts[firstChar] || 0) + 1;
    if (totals[firstChar] > 1) {
      shift.symbol = `${firstChar}${counts[firstChar]}`;
    } else {
      shift.symbol = firstChar;
    }
  });
};
