import { Route } from 'react-router-dom';
import { AppGuard } from './app-guard';
import { PageNotFound } from './page-not-found';
import { DrawerNavItem } from '@kidsmanager/ui-core';
import { FeatureLogin, FeatureGoogleCallback, FeatureLogout } from '@kidsmanager/feature-account';
import {
  TimesheetManager,
  TimesheetViewDay,
  TimesheetViewMonth,
  TimesheetViewWeek,
  TimesheetViewWeekDay
} from '@kidsmanager/feature-timesheet';
import {
  HolidayManager,
  HolidayViewCurrent,
  HolidayViewBooking,
  HolidayViewHistory
} from '@kidsmanager/feature-holiday';
import { AbsenceManager, AbsenceNew } from '@kidsmanager/feature-absence';
import { PwaRemove } from './pwa-remove';

const topLevelNavigation: DrawerNavItem[] = [
  { label: 'Arbeitszeit', path: '/work/my', icon: 'schedule', root: true },
  { label: 'Urlaub', path: '/holiday/my', icon: 'beach_access' },
  { label: 'Nichtleistungszeiten', path: '/absence', icon: 'event_busy' },
  { label: 'Ausloggen', path: '/logout', icon: 'logout', breakBefore: true }
];

export const AppRoutes = (): React.ReactNode => {
  return (
    <>
      <Route path="/login" element={<FeatureLogin brand="zeitmgr" />} />
      <Route path="/logout" element={<FeatureLogout />} />
      <Route path="/reset" element={<PwaRemove />} />
      <Route path="/google-callback" element={<FeatureGoogleCallback />} />
      <Route element={<AppGuard navigation={topLevelNavigation} />}>
        <Route index element={<TimesheetManager />} />
        <Route path="/work/:userId/:date?" element={<TimesheetManager />}>
          <Route index path="day" element={<TimesheetViewDay />} />
          <Route path="week" element={<TimesheetViewWeek />} />
          <Route path="month" element={<TimesheetViewMonth />} />
        </Route>
        <Route path="/work/:userId/:date/week/:day" element={<TimesheetViewWeekDay />} />
        <Route path="/holiday/:userId" element={<HolidayManager />}>
          <Route index element={<HolidayViewCurrent />} />
          <Route path="history" element={<HolidayViewHistory />} />
          <Route path="booking/:start" element={<HolidayViewBooking />} />
        </Route>
        <Route path="/absence/:date?" element={<AbsenceManager />}>
          <Route index element={<AbsenceNew />} />
        </Route>
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </>
  );
};
