import { Link } from 'react-router-dom';

export interface HolidayBookingItemProps {
  userId?: string;
  from: Date;
  to: Date;
  approvedBy?: string | null;
  approvedWhen?: string | null;
  days: number;
}

export const HolidayBookingItem = (props: HolidayBookingItemProps) => {
  const formatShortDate = (date: Date) => {
    return date.toLocaleString('de-DE', {
      day: '2-digit',
      month: 'long'
    });
  };
  const formatFullDate = (date: string | null | undefined) => {
    if (!date) return '???';
    return new Date(date).toLocaleString('de-DE', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    });
  };

  return (
    <Link
      to={`/holiday/${props.userId}/booking/${props.from.toLocaleISODate()}`}
      className="outline-focus -mx-1 inline-block w-full px-1 py-1"
    >
      <div>{`${formatShortDate(props.from)} - ${formatShortDate(props.to)}`}</div>
      {!props.approvedBy && (
        <div className="text-sm text-black/50">Best&auml;tigung ausstehend</div>
      )}
      {props.approvedBy && (
        <div className="text-sm text-black/50">
          {`Genehmigt ${formatFullDate(props.approvedWhen)}; ${props.approvedBy}`}
        </div>
      )}
      <span className="absolute right-2 top-3 text-xs">{props.days}d</span>
    </Link>
  );
};
