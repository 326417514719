import { DateSlider, Textarea } from '@kidsmanager/ui-core';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ShiftSelector } from './components/shift-selector';
import { NightDutyLogger } from './components/night-duty-logger';
import { ShiftSaveAs } from './components/shift-save-as';
import { YesterdayToggle } from './components/yesterday-toggle';
import { PlannedShift } from './components/planned-shift';
import { ClientBackendContext } from '@kidsmanager/ui-api';
import {
  IReleasedMemberPlan,
  ISaveAs,
  ISaveEntry,
  IShift,
  IShiftSpec
} from '@kidsmanager/util-models';
import { BreakDeclaration } from './components/break-declaration';
import { compileNightDutyToSubShifts } from '../timesheet-compilers/nightduty-compiler';
import { expandShift } from './helpers/shift-expander';
import { compileShift } from '../timesheet-compilers/shifts-compiler';

const ticksPerHour = 3600000;
const adjustableMins = 60 * 4;

const toHoursAndMins = (totalHours: number) => {
  const hours = Math.floor(totalHours);
  const mins = (totalHours * 60) % 60;
  return { hours, mins };
};

export const TimesheetViewDay = () => {
  const params = useParams<{ date: string }>();
  const [saving, setSaving] = useState(false);
  const [nominalStart, setNominalStart] = useState<Date>();
  const [nominalEnd, setNominalEnd] = useState<Date>();
  const [start, setStart] = useState<Date>();
  const [end, setEnd] = useState<Date>();
  const [yesterday, setYesterday] = useState(false);
  const [shifts, setShifts] = useState<IShift[]>([]);
  const [selected, setSelected] = useState<IShift>();
  const [nightDuty, setNightDuty] = useState<string[]>([]);
  const [notes, setNotes] = useState<string>();
  const [specs, setSpecs] = useState<IShiftSpec[]>();
  const [planned, setPlanned] = useState<IReleasedMemberPlan>();
  const client = useContext(ClientBackendContext);

  useEffect(() => {
    const date = params.date ? new Date(params.date) : new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;

    Promise.all([
      client.roster.plan.released(year, month),
      client.roster.shifts.for(year, month, 'my'),
      client.timesheet.shifts()
    ]).then(([released, specs, shifts]) => {
      const plan = released?.assigned[date.getDate() - 1];
      setPlanned(released);
      setShifts(shifts);
      setSpecs(specs);
      setSelected(compileShift(plan, shifts, specs));
      client.user.byPrimaryGroup(); //added here to preload cache for other views
    });
  }, [client, params.date]);

  useEffect(() => {
    if (!selected) return;
    const date = params.date ? new Date(params.date) : new Date();

    const { hours, mins } = toHoursAndMins(selected.nominalStartHours);
    const start = new Date(
      new Date(date).setHours(hours, mins) - (yesterday ? 24 * ticksPerHour : 0)
    );
    const end = new Date(
      new Date(start.getTime() + selected.nominalLengthHours * ticksPerHour)
    );
    setNominalStart(start);
    setNominalEnd(end);
    setStart(start);
    setEnd(end);
  }, [params, selected, yesterday]);

  const handleShiftChange = (shift: IShift) => {
    setSelected(shift);
  };

  const handleOnSaveAs = async (option?: ISaveAs, explanation?: string) => {
    if (!start || !end || !selected) return;

    setSaving(true);
    const infoOnly = option?.infoOnly || false;
    const subshifts = compileNightDutyToSubShifts(selected, nightDuty);
    const parts = expandShift(selected, start, end, subshifts, 0, infoOnly);

    const tosave = parts.map((part, index) => {
      const entry: ISaveEntry = {
        shiftId: selected.id,
        duration: (part.until.getTime() - part.from.getTime()) / 1000,
        breakDuration: part.breakHours * 3600,
        index,
        total: part.total,
        label: part.label,
        note: (index === 0 ? notes : undefined) || '',
        tag: option?.abbr || '',
        type: 'work',
        code: '',
        infoOnly: part.infoOnly
      };
      return client.timesheet.log.create(part.from, entry);
    });

    try {
      await Promise.all(tosave);
      setTimeout(() => setSaving(false), 250);
    } catch (error) {
      console.error(error);
    }
  };

  const handlePlannedShiftChange = (id: string | undefined) => {
    if (!id || !specs) return;
    const shift = compileShift({ shifts: [id] }, shifts, specs);
    setSelected(shift);
  };

  const show = selected && start && end && nominalStart && nominalEnd;
  return (
    show && (
      <div className="relative flex flex-col gap-6 px-4 py-5">
        <PlannedShift
          date={params.date}
          planned={planned}
          selected={selected}
          specs={specs}
          onChange={handlePlannedShiftChange.bind(this)}
        >
          <ShiftSelector
            selected={selected}
            shifts={shifts}
            onChange={handleShiftChange.bind(this)}
          />
          <YesterdayToggle
            hide={!selected.nightshift}
            date={params.date ? new Date(params.date) : new Date()}
            checked={yesterday}
            onChange={setYesterday}
          />
        </PlannedShift>
        <DateSlider
          nominal={nominalStart}
          value={start}
          plus={adjustableMins}
          minus={adjustableMins}
          color="primary"
          invert={true}
          onChange={setStart}
        />
        <DateSlider
          nominal={nominalEnd}
          value={end}
          plus={adjustableMins}
          minus={adjustableMins}
          color="primary"
          onChange={setEnd}
        />
        <NightDutyLogger
          shift={selected}
          onChange={setNightDuty.bind(this)}
          selected={nightDuty}
        />
        <BreakDeclaration
          selected={selected}
          start={start}
          end={end}
          onChange={console.log}
        />
        <ShiftSaveAs
          saving={saving}
          start={start}
          end={end}
          options={selected.saveAs}
          onSaveAs={handleOnSaveAs.bind(this)}
        />
        <Textarea
          placeholder="Optionale Notizen"
          rows={6}
          onChange={setNotes.bind(this)}
        >
          {notes}
        </Textarea>
      </div>
    )
  );
};
