import '@kidsmanager/util-extensions';
import { useContext, useEffect, useMemo } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  DatePicker,
  DrawerContext,
  DrawerTabs,
  DrawerTabItem,
  MonthPickerView
} from '@kidsmanager/ui-core';

export const TimesheetManager = () => {
  const drawer = useContext(DrawerContext);
  const params = useParams<{ date: string; userId: string }>();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.split('/').length < 4) {
      navigate(
        `/work/${params.userId || 'my'}/${new Date().toLocaleISODate()}/day`
      );
    }
  }, [location, params.userId, navigate]);

  const tabs: DrawerTabItem[] = useMemo(() => {
    const selected = params.date ? new Date(params.date) : new Date();
    const week = selected.getWeek();

    const dateFromMonth = (year: number, month: number): Date => {
      const lastDayOfMonth = new Date(year, month, 0);
      return new Date(
        year,
        month - 1,
        Math.min(selected.getDate(), lastDayOfMonth.getDate())
      );
    };

    const handleDateChange = (date: Date, scope: 'day' | 'week' | 'month') => {
      // Push to next event loop in order for browser to update the address bar
      setTimeout(
        () =>
          navigate(`/work/${params.userId}/${date.toLocaleISODate()}/${scope}`),
        0
      );
      const sameYear = selected.getFullYear() === date.getFullYear();
      if (sameYear || scope !== 'month') {
        (document.activeElement as HTMLElement)?.blur();
      }
    };

    return [
      {
        label: selected.toUserFriendlyString('de-DE'),
        path: `/work/my/${selected.toLocaleISODate()}/day`,
        root: true,
        contextPopup: (
          <DatePicker
            current={selected}
            onSelected={(date) => handleDateChange(date, 'day')}
          />
        )
      },
      {
        label: `Woche ${week}`,
        path: `/work/${params.userId}/${selected.toLocaleISODate()}/week`,
        contextPopup: (
          <DatePicker
            current={selected}
            mode="week"
            onSelected={(date) => handleDateChange(date, 'week')}
          />
        )
      },
      {
        label: selected.toLocaleString('de-DE', { month: 'short' }),
        path: `/work/${params.userId}/${selected.toLocaleISODate()}/month`,
        contextPopup: (
          <MonthPickerView
            year={selected.getFullYear()}
            month={selected.getMonth() + 1}
            onChange={(year, month) =>
              handleDateChange(dateFromMonth(year, month), 'month')
            }
          />
        )
      }
    ];
  }, [params.date, params.userId, navigate]);

  drawer.configure('Arbeitszeit', <DrawerTabs tabs={tabs} />);

  return <Outlet />;
};
