import { ClientBackendContext } from '@kidsmanager/ui-api';
import {
  UserPicker,
  UserPickerGroup,
  UserPickerUser
} from '@kidsmanager/ui-core';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const formatUsername = (lastName?: string, firstName?: string): string => {
  if (!firstName) {
    return lastName || '';
  }
  return `${lastName}, ${firstName}`;
};

export interface HolidayLowerHeaderProps {
  onUserSelected?: (userId: string) => void;
}

export const HolidayLowerHeader = (props: HolidayLowerHeaderProps) => {
  const [user, setUser] = useState<UserPickerUser>();
  const [groups, setGroups] = useState<UserPickerGroup[]>([]);
  const client = useContext(ClientBackendContext);
  const params = useParams<{ userId: string }>();

  useEffect(() => {
    client.user.byPrimaryGroup().then((groups) => {
      const selectedUserId = params.userId === 'my' ? '' : params.userId;
      const selectedUser = groups.flatMap((g) =>
        g.users.filter((u) => u.userId === selectedUserId)
      )[0];
      if (selectedUser) {
        setUser({
          userId: selectedUser.userId,
          name: formatUsername(selectedUser.lastName, selectedUser.firstName)
        });
      }
      setGroups(
        groups.map((g) => ({
          name: g.name,
          users: g.users.map((user) => ({
            userId: user.userId || '',
            name: formatUsername(user.lastName, user.firstName)
          }))
        }))
      );
    });
  }, [client, params.userId]);

  const onUserSelected = (user: UserPickerUser) => {
    setUser(user);
    props.onUserSelected?.(user.userId || 'my');
    (document.activeElement as HTMLElement)?.blur();
  };

  return (
    <div
      className={`border-silver-100 relative -mx-2 -mt-1 mb-1 border-b ${params.userId === 'my' ? 'bg-neutral-100' : 'bg-yellow-50'} py-3`}
    >
      <UserPicker
        selected={user}
        groups={groups}
        onSelected={onUserSelected.bind(this)}
      />
    </div>
  );
};
