import { Period48 } from '@kidsmanager/util-common';
import { IShift, ISubShift } from '@kidsmanager/util-models';

const to48hrTimestamp = (night: ISubShift, value: number): number => {
  //if value greater than nightshift start, then in first 24 hours, else in second 24 hours
  return value > night.start ? value : value + 24 * 3600;
};

export const compileNightDutyToSubShifts = (
  shift: IShift | undefined,
  selected: string[]
): Period48[] => {
  const periods: Period48[] = [];
  if (!shift || !shift.onCall || !shift.nightshift) return periods;

  const onCallStart48 = to48hrTimestamp(shift.nightshift, shift.onCall.start);
  const onCallEnd48 = to48hrTimestamp(shift.nightshift, shift.onCall.end);
  const nightStart48 = shift.nightshift.start;
  const nightEnd48 = to48hrTimestamp(shift.nightshift, shift.nightshift.end);

  //add period before oncall
  periods.push({
    start: nightStart48,
    end: onCallStart48,
    type: 'night'
  });

  //add oncall period
  periods.push({
    start: onCallStart48,
    end: onCallEnd48,
    type: 'oncall'
  });

  //add any night duty that's after oncall
  if (onCallEnd48 < nightEnd48) {
    periods.push({
      start: onCallEnd48,
      end: nightEnd48,
      type: 'night'
    });
  }

  //add each selected periods
  for (const value of selected) {
    const [hours, mins] = value.split(':').map((r) => parseInt(r));
    const start = to48hrTimestamp(shift.nightshift, hours * 3600 + mins * 60);
    const end = start + 1800;
    periods.push({ start, end, type: 'night' });
  }

  //Merge touching periods of the same type
  periods.sort((a, b) => a.start - b.start);
  const merged: Period48[] = [];
  for (const p of periods.filter((r) => r.type === 'night')) {
    if (p.start === merged[merged.length - 1]?.end) {
      merged[merged.length - 1].end = p.end;
    } else {
      merged.push(p);
    }
  }
  merged.push(...periods.filter((r) => r.type === 'oncall'));
  merged.sort((a, b) => a.start - b.start);

  return merged;
};
