import { useId } from 'react';

export interface CheckboxProps {
  id?: string;
  name?: string;
  checked?: boolean;
  defaultChecked?: boolean;
  children?: React.ReactNode;
  onChange?: (checked: boolean) => void;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
}

export const Checkbox = (props: CheckboxProps) => {
  const id = useId();

  return (
    <label
      className="inline-flex cursor-pointer items-center gap-2 leading-8"
      htmlFor={id}
    >
      <input
        id={props.id || id}
        name={props.name}
        type="checkbox"
        className="border-silver-400 checked:bg-checkmark checked:animate-checkmark outline-focus h-5 w-5 cursor-pointer appearance-none rounded-md border bg-no-repeat outline-offset-4"
        defaultChecked={props.defaultChecked}
        checked={props.checked}
        onChange={props.onChange?.bind(this, !props.checked)}
        onClick={props.onClick?.bind(this)}
      />
      <span>{props.children}</span>
    </label>
  );
};
