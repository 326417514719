import { useNavigate } from 'react-router-dom';
import { DrawerNav, DrawerNavItem } from './drawer-nav';

export interface DrawerProps {
  title: string;
  lines?: string[];
  displayName: string;
  brand: 'zeitmgr' | 'kidsmgr';
  navigation: DrawerNavItem[];
  back?: string;
  toolbar?: React.ReactNode;
  children?: React.ReactNode;
  fullWidth?: boolean;
}

/**
 * Drawer collapses sidebar on screens < 768px (md: breakpoint)
 */
export const Drawer = (props: DrawerProps) => {
  const navigate = useNavigate();
  const onBack = () => {
    if (props.back === '..') {
      navigate(-1);
    } else if (props.back) {
      navigate(props.back);
    }
  };

  return (
    <div className="relative h-screen grid-cols-[256px_auto] bg-neutral-50 md:grid">
      <input
        id="drawer-toggle"
        type="checkbox"
        className="peer absolute hidden"
      />

      <div
        id="drawer-content"
        className={`fixed inset-0 order-2 overflow-auto bg-white transition-all duration-300 md:left-64 ${props.fullWidth ? '' : 'max-w-2xl'}`}
      >
        <header className="bg-primary text-contrast shadow-hdr sticky top-0 z-50 border-b border-neutral-200 text-xl">
          <div className="flex min-h-14 items-center pl-2 md:pl-6">
            {!props.back && (
              <label
                htmlFor="drawer-toggle"
                className="mr-1 flex h-12 w-12 cursor-pointer items-center justify-center rounded-full transition-colors hover:bg-black/10 active:bg-black/15 md:hidden"
              >
                <span className="material-icons">menu</span>
              </label>
            )}
            {props.back && (
              <span
                className="material-icons outline-focus mr-4 cursor-pointer rounded-md outline-2 outline-offset-2 focus-within:outline"
                onClick={onBack.bind(this)}
                onKeyDown={(e) => (e.key === 'Enter' ? onBack() : null)}
                tabIndex={0}
              >
                arrow_back
              </span>
            )}
            {props.title}
          </div>
          {props.lines && props.lines.length > 0 && (
            <div className="-mt-1 pb-4">
              {props.lines.map((line, index) => (
                <div
                  key={index}
                  className={`ml-16 ${index === (props.lines?.length || 0) - 1 ? 'text-base font-light opacity-70' : ''}`}
                >
                  {line}
                </div>
              ))}
            </div>
          )}

          <div>{props.toolbar}</div>
        </header>
        <div className="p-2">{props.children}</div>
      </div>

      <div
        id="drawer-overlay"
        className="absolute inset-0 -translate-x-full bg-black opacity-0 transition-opacity duration-150 peer-checked:translate-x-0 peer-checked:opacity-60 md:hidden"
      >
        <label
          htmlFor="drawer-toggle"
          className="absolute inset-0 cursor-pointer"
        />
      </div>

      <div
        id="drawer-sidebar"
        className="fixed inset-0 z-10 w-64 -translate-x-full bg-white shadow-lg transition-transform duration-300 peer-checked:translate-x-0 md:relative md:translate-x-0 md:duration-0"
      >
        <DrawerNav
          displayName={props.displayName}
          brand={props.brand}
          items={props.navigation}
        />
      </div>
    </div>
  );
};
