import {
  IDay,
  IHolidayBooking,
  IMonth,
  IValueFrom
} from '@kidsmanager/util-models';
import {
  extractFreetime,
  extractTimes,
  extractInfoOnly,
  extractNightshift,
  extractNonWork,
  extractNotes,
  extractSickLeave,
  extractStandby,
  extractTags,
  extractWorked,
  periodIncludes,
  toTime,
  holidaysToMonth
} from './mapper-helpers';
import { IHoursForMonth } from 'libs/ui-api/src/lib/timesheet/api-timesheet-helper';
import { i } from 'vitest/dist/reporters-yx5ZTtEV';

export interface DaySummary {
  date: Date;
  dayOfWeek: string;
  shortDate: string;
  times: string;
  breaks: number;
  worked: number;
  dayShift: number;
  nightShift: number;
  nightStandby: number;
  nonWork: number;
  infoOnly: number;
  sickLeave: number;
  sunPubHol: number;
  notes: string;
  tags: string[];
  timeChange: number;
  isWeekend: boolean;
  isPublicHoliday: boolean;
}

export interface MonthBaseSummary {
  contracted: number;
  planned: number;
  total: number;
  previous: number;
  adj: number;
}

export interface BreakdownSummary {
  nightshift: number;
  nightstandby: number;
  sundayPubHol: number;
  sickLeave: number;
  sickLeaveDays: number;
}
export interface HolidaySummary {
  forYear: number;
  toStart: number;
  toEnd: number;
}

export const mapBreakdown = (days: DaySummary[]) => {
  return {
    nightshift: days.reduce((acc, d) => acc + d.nightShift, 0) / 3600,
    nightstandby: days.reduce((acc, d) => acc + d.nightStandby, 0) / 3600,
    sundayPubHol: days.reduce((acc, d) => acc + d.sunPubHol, 0) / 3600,
    sickLeave: days.reduce((acc, d) => acc + d.sickLeave, 0) / 3600,
    sickLeaveDays: days.filter(
      (d) => d.tags.includes('KS') || d.tags.includes('gKS')
    ).length
  };
};

export const mapHolidaySummary = (
  year: number,
  month: number,
  bookings: IHolidayBooking[],
  publicHols: Date[],
  holidayAllowance: number
) => {
  const hoidaysToStart = holidaysToMonth(bookings, publicHols, year, month - 1);
  const hoidaysToEnd = holidaysToMonth(bookings, publicHols, year, month);
  return {
    forYear: holidayAllowance,
    toStart: hoidaysToStart,
    toEnd: hoidaysToEnd
  };
};

export const mapCurrentMonthSummary = (
  year: number,
  month: number,
  data: IMonth,
  actual: number,
  carryForward: number,
  correction: number,
  contracted: IHoursForMonth,
  workingDays: number
): MonthBaseSummary => {
  return {
    contracted: contracted.default * 5,
    planned: contracted.default * workingDays,
    total: data.worked[month - 1],
    previous: carryForward,
    adj: correction
  };
};

export const mapAverageMonthSummary = (
  year: number,
  month: number,
  data: IMonth,
  actual: number,
  correction: number,
  contracted: IHoursForMonth,
  average: { weekly: number; monthly: number }
): MonthBaseSummary => {
  const startMonth = getStartMonth(contracted.raw, year);
  const workingMonthsToDate = month - 1 - startMonth;
  const plannedToDate = average.monthly * workingMonthsToDate;
  const workToDate = data.worked.reduce(
    (acc, d, i) => (i < month - 1 ? acc + d : acc),
    0
  );
  const adjustments = data.adjustments.reduce(
    (acc, d, i) => (i < month - 1 ? acc + d : acc),
    0
  );

  return {
    contracted: average.weekly,
    planned: average.monthly,
    total: data.worked[month - 1],
    previous: workToDate - plannedToDate + adjustments,
    adj: correction
  };
};

export const mapDaySummary = (
  loggedDays: IDay[],
  bookings: IHolidayBooking[],
  publicHols: Date[],
  contracted: IHoursForMonth
): DaySummary[] => {
  return loggedDays.map((day) =>
    mapDay(day, bookings, publicHols, contracted.default)
  );
};

export const getStartMonth = (values: IValueFrom[], year: number) => {
  const firstFrom = values
    .map((x) => new Date(x.from))
    .sort((a, b) => a.getTime() - b.getTime())
    .at(0);

  return firstFrom && firstFrom.getTime() > new Date(year, 0, 1).getTime()
    ? firstFrom.getMonth()
    : 0;
};

const mapDay = (
  day: IDay,
  bookings: IHolidayBooking[],
  publicHols: Date[],
  contracted: number
): DaySummary => {
  const d = new Date(day.date);

  const detail = initialDaySummary(d);
  detail.isWeekend = d.getDay() === 0 || d.getDay() === 6;
  detail.isPublicHoliday = publicHols.some((ph) => ph.dateMatches(d));
  detail.dayOfWeek = d.toLocaleDateString('de-DE', { weekday: 'short' });
  detail.shortDate = d.toLocaleDateString('de-DE', {
    day: '2-digit',
    month: '2-digit'
  });

  if (day.entries.length > 0) {
    const entries = day.entries.sort((a, b) =>
      a.startSeconds > b.startSeconds ? 1 : -1
    );

    const { times, from, until } = extractTimes(d, entries);
    const { standby, timeChange } = extractStandby(entries);

    detail.times = times;
    detail.timeChange = timeChange;
    detail.nightStandby = standby;
    detail.nightShift = extractNightshift(entries);
    detail.worked = extractWorked(entries);
    detail.sunPubHol = extractFreetime(entries, publicHols);
    detail.notes = extractNotes(entries);
    detail.tags = extractTags(entries);
    detail.dayShift = detail.worked - detail.nightShift;

    if (!detail.isPublicHoliday) {
      detail.nonWork = extractNonWork(entries);
      detail.infoOnly = extractInfoOnly(entries);
      detail.sickLeave = extractSickLeave(entries);
    }

    detail.breaks =
      until -
      from -
      (detail.worked +
        detail.nightStandby +
        detail.nonWork -
        detail.timeChange);
  }
  if (
    !detail.isPublicHoliday &&
    !detail.isWeekend &&
    bookings.find((b) => periodIncludes(b, d))
  ) {
    detail.nonWork += contracted * 3600;
    detail.times = `${toTime(9 * 3600)} - ${toTime(9 * 3600 + detail.nonWork)}`;
    detail.tags.push('UT');
  }
  return detail;
};

const initialDaySummary = (date: Date): DaySummary => {
  return {
    date: new Date(date),
    dayOfWeek: '',
    shortDate: '',
    times: '',
    breaks: 0,
    worked: 0,
    dayShift: 0,
    nightShift: 0,
    nightStandby: 0,
    nonWork: 0,
    infoOnly: 0,
    sickLeave: 0,
    sunPubHol: 0,
    notes: '',
    timeChange: 0,
    tags: [],
    isWeekend: false,
    isPublicHoliday: false
  };
};
