import { useEffect, useState } from 'react';
import './slider.css';
export interface SliderProps {
  min: number;
  max: number;
  step: number;
  value: number;
  invert?: boolean;
  color?: 'primary' | 'accent';
  onChange?: (value: number) => void;
}

export const Slider = (props: SliderProps) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(Number(e.target.value));
    props.onChange?.(Number(e.target.value));
  };

  return (
    <input
      className={`range outline-focus outline-offset-2 ${props.invert ? 'range-invert' : ''}`}
      type="range"
      min={props.min}
      max={props.max}
      step={props.step}
      value={value}
      onChange={onChange.bind(this)}
    />
  );
};
