export class HolidayProRata<T> {
  changes: { from: Date; value: number }[] = [];
  days = 0;
  value = 0;

  constructor(
    changes: T[],
    valueField: keyof T,
    dateField: keyof T = 'from' as keyof T
  ) {
    if (!changes) {
      return;
    }
    this.changes = changes.map((x) => ({
      from: new Date(x[dateField] as string),
      value: x[valueField] as number
    }));
    this.changes.sort((a, b) => (a.from > b.from ? 1 : -1));
  }

  forYear(year: number): number {
    this.value = 0.0;
    const start = new Date(Date.UTC(year, 0, 1));
    const end = new Date(Date.UTC(year + 1, 0, 1));
    this.days = HolidayProRata.daysUntil(start, end);
    if (this.changes.length === 0) {
      return 0;
    }

    const index = (() => {
      for (let i = this.changes.length - 1; i >= 0; i--) {
        if (this.changes[i].from < end) {
          return i;
        }
      }
      return -1; // If no match is found, return -1
    })();

    let value = 0;
    for (let i = index; i >= 0; i--) {
      const from = start > this.changes[i].from ? start : this.changes[i].from;
      const to = i < index ? this.changes[i + 1].from : end;
      const diff = HolidayProRata.daysUntil(from, to);

      value += this.changes[i].value * (diff / this.days);

      //break when we finished with all current allowances
      if (i > 0 && this.changes[i].from <= start) {
        break;
      }
    }
    this.value = Math.round(value);
    return this.value;
  }

  static daysUntil(from: Date, until: Date): number {
    return (until.getTime() - from.getTime()) / (24 * 60 * 60 * 1000);
  }
}
