import { MaskOptions } from './mask-options';

export const maskToInputMode = (mask: MaskOptions) => {
  if (mask === 'number') {
    return 'decimal';
  }
  if (mask === 'email') {
    return 'email';
  }
  if (mask === 'phone') {
    return 'tel';
  }
  return 'text';
};

export const applyMask = (value: string, mask: MaskOptions) => {
  if (mask === 'time') {
    return applyTimeMask(value);
  }
  if (mask === 'date' || mask === 'day') {
    return applyDateMask(value);
  }
  if (mask === 'email') {
    return applyEmailMask(value);
  }
  if (mask === 'phone') {
    return applyPhoneMask(value);
  }
  if (mask === 'username') {
    return applyUsernameMask(value);
  }
  if (mask === 'names') {
    return applyNamesMask(value);
  }
  return value;
};

const applyTimeMask = (value: string) => {
  const time = value.replace(/[^0-9:]/g, '');
  return time;
};

const applyDateMask = (value: string) => {
  const date = value.replace(/[^0-9.\\/]/g, '');
  return date.substring(0, 10);
};

const applyPhoneMask = (value: string) => {
  const phone = value.replace(/[^0-9+\s]/g, '');
  return phone;
};

const applyEmailMask = (value: string) => {
  const [local, domain] = value.split('@');
  //remove non valid email local part characters from local
  const localPart = local.replace(/[^.a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]/g, '');
  //remove non valid email domain part characters from domain
  const domainPart = domain?.replace(/[^a-zA-Z0-9.-]/g, '');
  return domain !== undefined ? `${localPart}@${domainPart}` : localPart;
};

const applyUsernameMask = (value: string) => {
  value = value.toLowerCase();

  if (value.length === 1) {
    return value.replace(/[^a-z0-9äöüß]/g, '');
  } else {
    return value.replace(/[,:;]/g, '.').replace(/[^a-z0-9äöüß._-]/g, '');
  }
};

const applyNamesMask = (value: string) => {
  return value.replace(/[^a-zA-ZäöüßÄÖÜ\s-]/g, '');
};
