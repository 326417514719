import '@kidsmanager/util-extensions';
import { useEffect, useMemo, useState } from 'react';

import { DateCalendar } from './date-calendar';
import { generateWeeks } from './date-helpers';

export interface DatePickerProps {
  current: Date;
  mode?: 'day' | 'week';
  onSelected?: (date: Date) => void;
}

export const DatePicker = (props: DatePickerProps) => {
  const [d, setCurrent] = useState(props.current);
  const [outline, setOutline] = useState<Date>();
  const mode = props.mode ?? 'day';
  const weeks = useMemo(() => generateWeeks(d, []), [d]);
  const label = useMemo(
    () =>
      `${d
        .toLocaleString('de-DE', {
          month: 'short'
        })
        .replace('.', '')} ${d.getFullYear()}`,
    [d]
  );

  useEffect(() => {
    setOutline(new Date(props.current));
  }, [props]);

  const onDayClicked = (e: React.MouseEvent, date: Date | undefined) => {
    e.stopPropagation();
    e.preventDefault();
    if (date === undefined) return;
    setCurrent(date);
    props.onSelected?.(date);
  };

  return (
    <div
      className={`relative min-h-48 rounded-md border border-neutral-200 p-2 text-black ${mode === 'week' ? 'w-72' : 'w-64'}`}
    >
      <div className="mb-2 flex items-center text-sm">
        <span className="flex-1">{label}</span>
        <button
          className="material-icons h-8 w-8 rounded-full hover:bg-neutral-200"
          onClick={() =>
            setCurrent(new Date(d.getFullYear(), d.getMonth() - 1, d.getDate()))
          }
        >
          chevron_left
        </button>
        <button
          className="material-icons h-8 w-8 rounded-full hover:bg-neutral-200"
          onClick={() =>
            setCurrent(new Date(d.getFullYear(), d.getMonth() + 1, d.getDate()))
          }
        >
          chevron_right
        </button>
      </div>
      <DateCalendar
        outline={outline}
        weeks={weeks}
        mode={mode}
        onClicked={onDayClicked.bind(this)}
      />
      <div className="hover:text-accent m-1 cursor-pointer text-right text-xs underline">
        <span onClick={(e) => onDayClicked(e, new Date())}>Heute</span>
      </div>
    </div>
  );
};
