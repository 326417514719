import { HolidayProRata } from '@kidsmanager/util-common';
import { IHolidayAllowance, IHolidayBooking } from '@kidsmanager/util-models';

export interface Booking extends IHolidayBooking {
  days: number;
}

export const holidaysInYear = (
  year: number,
  bookings: IHolidayBooking[],
  publicHolidays: Date[]
) => {
  const firstOfYear = new Date(year, 0, 1);
  const lastOfYear = new Date(year, 11, 31);
  return bookings.reduce((acc, booking) => {
    if (
      booking.from.getFullYear() !== year &&
      booking.to.getFullYear() !== year
    )
      return acc;
    if (booking.from < firstOfYear) {
      acc += firstOfYear.workingDaysUntil(booking.to, publicHolidays);
    } else if (booking.to >= lastOfYear) {
      acc += booking.from.workingDaysUntil(lastOfYear, publicHolidays);
    } else {
      acc += booking.from.workingDaysUntil(booking.to, publicHolidays);
    }
    return acc;
  }, 0);
};

export const availableInYear = (
  year: number,
  allowances: IHolidayAllowance[]
) => {
  return new HolidayProRata(allowances, 'days').forYear(year);
};
