import { useContext, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { ClientBackendContext } from '@kidsmanager/ui-api';
import { DrawerNavItem, DrawerProvider } from '@kidsmanager/ui-core';

export const AppGuard = (props: { navigation: DrawerNavItem[] }) => {
  const client = useContext(ClientBackendContext);
  const [displayName, setDisplayName] = useState<string | undefined>(
    client.auth.displayName() || undefined
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (!client.auth.isAuthenticated()) {
      console.log('Not authenticated, redirecting to login');
      setDisplayName(undefined);
      client.auth.refresh().then((state) => {
        if (state !== 'Authenticated') {
          console.log('Refresh failed, redirecting to login');
          navigate('/login');
        }
        setDisplayName(client.auth.displayName() || '');
      });
    }
  }, [client, navigate]);

  return client.auth.isAuthenticated() ? (
    <DrawerProvider
      displayName={displayName || ''}
      brand="zeitmgr"
      title="ZeitManager"
      navigation={props.navigation}
    >
      <Outlet />
    </DrawerProvider>
  ) : undefined;
};
