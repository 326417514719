import { useEffect, useState } from 'react';

export interface PlannedSwitcherProps {
  options?: { id: string; label: string }[];
  selected?: string;
  onChange?: (id?: string) => void;
}

export const PlannedSwitcher = (props: PlannedSwitcherProps) => {
  const [selected, setSelected] = useState<string>();

  useEffect(() => {
    setSelected(props.selected);
  }, [props.selected]);

  const handleChange = (id: string | undefined) => {
    setSelected(id);
    props.onChange?.(id);
  };

  return (
    <div className="flex gap-1">
      {(props.options?.length || 0) > 1 &&
        props.options?.map((x, index) => (
          <div
            key={index}
            className={`w-7 cursor-pointer rounded-lg border-2 bg-neutral-200 uppercase leading-6 text-black hover:bg-neutral-300 ${x.id === selected ? 'border-accent' : 'bg-neutral-100'}`}
            onClick={handleChange.bind(this, x.id)}
          >
            {x.label[0]}
          </div>
        ))}
      <div className="flex cursor-pointer items-center rounded-lg bg-neutral-200 stroke-black/50 hover:bg-neutral-300">
        <span className="material-icons">close</span>
      </div>
    </div>
  );
};
