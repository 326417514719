/**
 * Format number of seconds to decimal hours or hh:mm
 * @param seconds number of seconds
 * @param format
 */
export const formatSeconds = (
  seconds: number,
  format: 'decimal' | 'hh:mm' = 'decimal',
  noZero = true
) => {
  if (!seconds) {
    return noZero ? '' : (0).toFixed(1);
  }
  if (format === 'decimal') {
    const hours = seconds / 3600.0;
    return hours.toFixed(2).replace(/\.?0$/, '');
  }

  const hours = Math.floor(seconds / (60 * 60));
  const mins = Math.floor((seconds - hours * 60 * 60) / 60);
  return `${hours}h${mins.toFixed(0).padStart(2, '0')}`;
};

export const formatNum = (value: number) => {
  return value.toFixed(2);
};
