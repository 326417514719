import { Revealer } from '@kidsmanager/ui-core';
import { IShift } from '@kidsmanager/util-models';
import { useMemo } from 'react';

export interface BreakDeclarationProps {
  start: Date;
  end: Date;
  selected: IShift;
  onChange?: (value: string) => void;
}

export const BreakDeclaration = ({
  start,
  end,
  selected: shift
}: BreakDeclarationProps) => {
  // const nightShiftDurations = (shift: IShift, start: Date, end: Date) => {
  //   if (!shift.nightshift) return 0;
  //   const nightShiftStart = start.setTotalSeconds(shift.nightshift.start);
  //   const nightShiftEnd = start.setTotalSeconds(
  //     shift.nightshift.end + 24 * 3600
  //   );
  //   return nightShiftEnd.getTime() - nightShiftStart.getTime();
  // };

  const requiredBreaks = useMemo(() => {
    const hours = (end.getTime() - start.getTime()) / 3600000;
    if (hours <= 6) return 0;
    if (hours <= 12) return 1;
    return Math.floor(hours / 12);
  }, [start, end]);

  return (
    <Revealer show={requiredBreaks > 0}>
      <p className="mx-auto max-w-sm text-center text-black/50">
        {requiredBreaks > 1 ? ` ${requiredBreaks}x ` : ''} 30-min&uuml;tige
        Pause genommen
      </p>
    </Revealer>
  );
};
