export const IconGoogle = () => {
  return (
    <svg
      width="28"
      height="26"
      viewBox="0 0 28 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.69697 12.7111C5.69697 11.8855 5.84699 11.0939 6.11475 10.3515L1.42804 7.08009C0.514626 8.77529 0 10.6854 0 12.7111C0 14.7351 0.513993 16.644 1.42614 18.3381L6.11032 15.0604C5.84509 14.3214 5.69697 13.5327 5.69697 12.7111Z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9259 5.2C15.8882 5.2 17.6606 5.83556 19.0532 6.87556L23.1044 3.17778C20.6357 1.21333 17.4707 0 13.9259 0C8.42265 0 3.6929 2.87676 1.42804 7.08009L6.11475 10.3515C7.19464 7.35511 10.2729 5.2 13.9259 5.2Z"
        fill="#EB4335"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9259 20.2222C10.2729 20.2222 7.19464 18.0671 6.11475 15.0708L1.42804 18.3416C3.6929 22.5455 8.42265 25.4222 13.9259 25.4222C17.3226 25.4222 20.5654 24.3198 22.9993 22.2543L18.5506 19.1106C17.2954 19.8334 15.7148 20.2222 13.9259 20.2222Z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.2189 12.7111C27.2189 11.96 27.0923 11.1511 26.9024 10.4H13.9259V15.3111H21.3953C21.0218 16.9855 20.0052 18.2728 18.5506 19.1106L22.9993 22.2543C25.556 20.0853 27.2189 16.8543 27.2189 12.7111Z"
        fill="#4285F4"
      />
    </svg>
  );
};
