import { useContext, useEffect, useMemo, useState } from 'react';
import { DrawerContext, DrawerTabs } from '@kidsmanager/ui-core';
import { Outlet, useParams } from 'react-router-dom';
import { ClientBackendContext } from '@kidsmanager/ui-api';
import {
  availableInYear,
  holidaysInYear
} from '../holiday-common/holiday-helpers';

export const HolidayManager = () => {
  const drawer = useContext(DrawerContext);
  const client = useContext(ClientBackendContext);
  const params = useParams<{ start?: string; userId: string }>();

  const [availableLabel, setAvailableLabel] = useState('');
  const [displayName, setDisplayName] = useState('');

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    Promise.all([
      client.user.find(params.userId),
      client.holiday.bookings(params.userId),
      client.holiday.allowances(params.userId),
      client.holiday.publicHolidays(year - 1, year, year + 1)
    ]).then(([user, bookings, allowances, publicHolidays]) => {
      const soFar = holidaysInYear(year, bookings, publicHolidays);
      const yearTotal = availableInYear(year, allowances);
      if (yearTotal === 0) {
        setAvailableLabel(`Urlaubstage noch nicht konfiguriert`);
      } else {
        setAvailableLabel(`${yearTotal - soFar} von ${yearTotal} verbleibend`);
      }
      setDisplayName(`${user.firstName} ${user.lastName}`);
    });
  }, [client, params.userId]);

  useEffect(() => {
    if (params.start) {
      const start = new Date(params.start || Date.now());
      const from = `von ${start.toLocaleString('de-DE', {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      })}`;

      drawer.configure(['Urlaub', displayName, from], null, '..');
    } else {
      const tabs = [
        { label: 'Aktuell', path: `/holiday/${params.userId}`, root: true },
        { label: 'Verlauf', path: `/holiday/${params.userId}/history` }
      ];
      drawer.configure(['Urlaub', availableLabel], <DrawerTabs tabs={tabs} />);
    }
  }, [drawer, availableLabel, params, displayName]);

  return <Outlet />;
};
