import { useMemo } from 'react';

export interface ProgressProps {
  mode?: 'determinate' | 'indeterminate';
  max?: number;
  value?: number;
}

export const Progress = (props: ProgressProps) => {
  const percent = useMemo(() => {
    return `${((props.value || 0) / (props.max || 100)) * 100}%`;
  }, [props.value, props.max]);

  return (
    <div className="border-1 w-full overflow-clip rounded-full border bg-gray-200">
      {props.mode === 'determinate' && (
        <div className="bg-km-blue h-2" style={{ width: percent }} />
      )}
      {props.mode === 'indeterminate' && (
        <div className="bg-km-blue animate-progress h-2 w-full" />
      )}
    </div>
  );
};
