import { IEntry } from '@kidsmanager/util-models';
import { useEffect, useState } from 'react';
import {
  formatEnd,
  formatStart
} from '../../timesheet-common/timesheet-formatters';

export interface DayOfWeek {
  date: Date;
  note?: string;
  entries: IEntry[];
}
export interface ViewWeekDayProps {
  day: DayOfWeek;
  userId: string;
  onClick?: () => void;
}

export const ViewWeekDay = (props: ViewWeekDayProps) => {
  const [totalDuration, setTotalDuration] = useState(0);
  const [infoOnlyDuration, setInfoOnlyDuration] = useState(0);

  useEffect(() => {
    if (props.day.entries === undefined) {
      setTotalDuration(0);
      setInfoOnlyDuration(0);
      return;
    }
    const total =
      props.day.entries
        .filter((x) => !x.infoOnly)
        .reduce(
          (sum, x) => sum + x.durationSeconds - x.breakDurationSeconds,
          0
        ) /
      (60 * 60);
    setTotalDuration(total);
    const infoOnly =
      props.day.entries
        .filter((x) => x.infoOnly)
        .reduce(
          (sum, x) => sum + x.durationSeconds - x.breakDurationSeconds,
          0
        ) /
      (60 * 60);
    setInfoOnlyDuration(infoOnly);
  }, [props.day.entries]);

  return (
    <div
      className="border-silver-200 relative mb-2 min-h-20 cursor-pointer border-b"
      onClick={props.onClick?.bind(this)}
    >
      <h2>
        {props.day.date.toLocaleString('de-DE', {
          weekday: 'long',
          day: '2-digit',
          month: 'short'
        })}
        {props.day.note && (
          <span className="rounded-full bg-teal-600/50 px-2 py-0.5 text-xs uppercase text-black/80">
            {props.day.note}
          </span>
        )}
      </h2>
      <span className="absolute right-2 top-1 text-xs text-black/50">
        <span>{totalDuration.toFixed(2)}</span>
        {infoOnlyDuration > 0 && <span> + {infoOnlyDuration.toFixed(2)}</span>}
      </span>
      <table className="mb-4 mt-1 w-full text-sm text-black/50">
        <tbody>
          {props.day.entries.map((entry, index) => (
            <tr key={`${props.day.date.toLocaleISODate()}-${index}`}>
              <td className="w-28">
                {formatStart(entry)}-{formatEnd(entry)}
              </td>
              <td className="flex items-center">
                <span>{entry.label}</span>
                {entry.note && (
                  <span
                    className="material-icons text-accent mx-1"
                    style={{ fontSize: '18px' }}
                  >
                    description
                  </span>
                )}
                {entry.tag && (
                  <span className="mx-1 rounded-lg bg-orange-400 px-0.5 py-[1px] text-xs text-white">
                    {entry.tag}
                  </span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
