export interface TextareaProps {
  id?: string;
  name?: string;
  children?: React.ReactNode;
  rows?: number;
  placeholder?: string;
  label?: string;
  value?: string;
  defaultValue?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
}

export const Textarea = (props: TextareaProps) => {
  const content = (
    <textarea
      id={props.id}
      name={props.name}
      className="outline-focus w-full rounded-md border border-neutral-200 p-2 outline-2 outline-offset-2 focus-within:outline"
      placeholder={props.placeholder}
      rows={props.rows}
      onChange={(e) => props.onChange?.(e.target.value)}
      value={props.value || (props.children as string)}
      defaultValue={props.defaultValue}
      disabled={props.disabled}
    />
  );

  return props.label ? (
    <label className="form-control w-full max-w-md">
      <div className="label">
        <span className="text-sm font-medium">{props.label}</span>
      </div>
      {content}
    </label>
  ) : (
    content
  );
};
