import '@kidsmanager/util-extensions';
import { useNavigate, useParams } from 'react-router-dom';
import { DayOfWeek, ViewWeekDay } from './components/view-week-day';
import { TimesheetLowerHeader } from '../timesheet-common/timesheet-lower-header';
import { useContext, useEffect, useState } from 'react';
import { TimesheetStats } from '../timesheet-common/timesheet-stats';
import { ClientBackendContext } from '@kidsmanager/ui-api';
import { emptyEntry } from '@kidsmanager/util-models';

export const TimesheetViewWeek = () => {
  const [actual, setActual] = useState(0);
  const [planned, setPlanned] = useState(0);
  const [date, setDate] = useState(new Date());
  const [week, setWeek] = useState(0);
  const [days, setDays] = useState<DayOfWeek[]>([]);

  const client = useContext(ClientBackendContext);
  const params = useParams<{ userId: string; date: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    const d = new Date(params.date || Date.now()).firstDayOfWeek();
    setDate(d);
    setWeek(d.getWeek());
  }, [params.date]);

  useEffect(() => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    Promise.all([
      client.timesheet.week(date, params.userId),
      client.timesheet.contracted(year, month, params.userId),
      client.holiday.bookingsForYear(year, params.userId),
      client.holiday.publicHolidays(year)
    ]).then(([week, contracted, holidays, publicHols]) => {
      setActual(week.worked);
      setPlanned(contracted.hoursPerWeek);
      setDays(
        week.current.map((value) => {
          const day: DayOfWeek = {
            date: new Date(value.date),
            entries: [...value.entries]
          };

          if (day.date.isWeekend()) {
            return day;
          }

          if (publicHols.find((x) => x.dateMatches(day.date))) {
            day.note = 'Feiertag';
            return day;
          } else {
            const booking = holidays.find(
              (h) => day.date >= h.from && day.date <= h.to
            );
            if (booking) {
              setActual((prev) => prev + contracted.hoursPerDay);
              day.entries.push({
                ...emptyEntry,
                date: day.date.toLocaleISODate(),
                label: 'Urlaub',
                startSeconds: 7 * 3600,
                durationSeconds: contracted.hoursPerDay * 3600
              });
            }
            return day;
          }
        })
      );
    });
  }, [client, params.date, params.userId, date]);

  const onDaySelected = (day: Date) => {
    navigate(
      `/work/${params.userId}/${params.date}/week/${day.toLocaleISODate()}`
    );
  };

  const onMove = (delta: number) => {
    const d = new Date(date.getTime() + delta * 24 * 60 * 60 * 1000);
    navigate(`/work/${params.userId}/${d.toLocaleISODate()}/week`);
  };

  const handleUserSelected = (userId: string) => {
    navigate(`/work/${userId}/${params.date}/week`);
  };

  return (
    <>
      <TimesheetLowerHeader onUserSelected={handleUserSelected.bind(this)}>
        <div className="flex items-center px-4 text-center text-2xl sm:block sm:text-3xl">
          <span
            className="material-icons cursor-pointer hover:text-black/50"
            style={{ userSelect: 'none' }}
            onClick={onMove.bind(this, -7)}
          >
            arrow_back_ios
          </span>
          <span className="text-nowrap md:mx-2">Woche {week}</span>
          <span
            className="material-icons cursor-pointer hover:text-black/50"
            style={{ userSelect: 'none' }}
            onClick={onMove.bind(this, 7)}
          >
            arrow_forward_ios
          </span>
        </div>
        <TimesheetStats actual={actual} planned={planned} />
      </TimesheetLowerHeader>
      <div className="py-2 pl-2 md:pl-6">
        {days.map((day) => (
          <ViewWeekDay
            key={day.date.toLocaleISODate()}
            userId={params.userId || 'my'}
            day={day}
            onClick={onDaySelected.bind(this, day.date)}
          />
        ))}
      </div>
    </>
  );
};
