export interface PopupButtonProps {
  label: string;
  children: React.ReactNode;
  onClick?: () => void;
}

export const PopupButton = (props: PopupButtonProps) => {
  return (
    <div className="relative flex justify-center">
      <div className="group outline-none" tabIndex={0}>
        <div
          className="flex cursor-pointer items-center text-xl text-black/50"
          onClick={props.onClick?.bind(this)}
        >
          {props.label}
          <span className="material-icons">arrow_drop_down</span>
        </div>
        <div className="shadow-hdr absolute left-1/2 top-8 z-50 hidden w-96 -translate-x-1/2 rounded bg-white p-2 group-focus-within:block">
          {props.children}
        </div>
      </div>
    </div>
  );
};
