import './select.css';
import { ChangeEvent, useState } from 'react';

export interface SelectProps {
  id?: string;
  name?: string;
  label?: string;
  children?: React.ReactNode;
  defaultValue?: string;
  disabled?: boolean;
  onChange?: (value: ChangeEvent<HTMLSelectElement>) => void;
}

export const Option = (props: { value: string; children: string }) => {
  return <option value={props.value}>{props.children}</option>;
};

export const Select = (props: SelectProps) => {
  const [unselected, setUnselected] = useState(!props.defaultValue);

  const onChange = (value: ChangeEvent<HTMLSelectElement>) => {
    setUnselected(!value.target.value);
    props.onChange?.(value);
  };
  const select = (
    <select
      id={props.id}
      name={props.name}
      className="select disabled:border-silver-100 outline-focus h-8 w-full max-w-2xl rounded-md border border-neutral-200 px-1 outline-2 outline-offset-4 placeholder:text-sm disabled:text-black/60"
      defaultValue={props.defaultValue}
      onChange={onChange.bind(this)}
      disabled={props.disabled}
      style={{
        color: unselected ? '#00000077' : ''
      }}
    >
      {props.children}
    </select>
  );
  return props.label ? (
    <label className="form-control w-full max-w-md">
      <div className="label">
        <span className="text-sm font-medium">{props.label}</span>
      </div>
      {select}
    </label>
  ) : (
    select
  );
};
