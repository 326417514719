import { INonWork, IShift } from '@kidsmanager/util-models';

export interface EntryOptions {
  work: string[];
  nonWork: INonWork[];
  allowBreaks: boolean;
  saveAs: { name: string; abbr: string; infoOnly: boolean }[];
}

export const extractWorkAndNonWorkTypes = (shifts: IShift[]): EntryOptions => {
  const options = {} as EntryOptions;

  options.nonWork = shifts
    .flatMap((x) => x.nonWork)
    .reduce((acc, value) => {
      if (acc.findIndex((x) => x.name === value.name) < 0) {
        acc.push({ ...value });
      }
      return acc;
    }, [] as INonWork[]);

  options.work = shifts
    .filter((x) => !x.fromTracking)
    .reduce((acc, shift) => {
      if (acc.findIndex((x) => x === shift.name) < 0) {
        acc.push(shift.name);
        if (shift.nightshift && acc.indexOf('Nachtdienst') < 0) {
          acc.push('Nachtdienst');
        }
        if (shift.onCall && acc.indexOf('Nachtbereitschaft') < 0) {
          acc.push('Nachtbereitschaft');
        }
      }
      return acc;
    }, [] as string[]);
  options.work.push('Team');
  options.work.push('Sonstiges');

  options.saveAs = shifts
    .flatMap((x) => x.saveAs)
    .reduce(
      (acc, saveAs) => {
        if (acc.findIndex((x) => x.name === saveAs.name) < 0) {
          acc.push({
            name: saveAs.name,
            abbr: saveAs.abbr,
            infoOnly: saveAs.infoOnly
          });
        }
        return acc;
      },
      [{ name: 'Normal', abbr: '', infoOnly: false }] as {
        name: string;
        abbr: string;
        infoOnly: boolean;
      }[]
    );

  options.allowBreaks = shifts.findIndex((x) => x.nominalBreakHours > 0) >= 0;

  return options;
};
