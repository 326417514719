import { IUser } from '@kidsmanager/util-models';
import { Page, Text, View, Document, Font } from '@react-pdf/renderer';
import {
  BreakdownSummary,
  DaySummary,
  HolidaySummary,
  MonthBaseSummary
} from './mapper-day-summary';
import { formatNum, formatSeconds } from './formatters';

export interface TimesheetPdfProps {
  date: Date;
  user: IUser;
  current: MonthBaseSummary;
  average: MonthBaseSummary;
  holiday: HolidaySummary;
  breakdown: BreakdownSummary;
  days: DaySummary[];
}

export const TimesheetPdf = (props: TimesheetPdfProps) => {
  const fonts = `${window.location.origin}/fonts/`;
  Font.register({
    family: 'Poppins',
    fonts: [
      { src: `${fonts}poppins-regular.ttf` },
      { src: `${fonts}poppins-medium.ttf`, fontWeight: 'medium' },
      { src: `${fonts}poppins-semibold.ttf`, fontWeight: 'semibold' },
      { src: `${fonts}poppins-bold.ttf`, fontWeight: 'bold' }
    ]
  });

  return (
    <Document>
      <Page
        size="A4"
        style={{
          fontFamily: 'Poppins',
          backgroundColor: 'white',
          fontSize: '12pt',
          paddingVertical: 32,
          paddingHorizontal: 24
        }}
      >
        {buildHeader(props.user, props.date)}
        {buildCalendar(props.days)}
        <View style={{ flexDirection: 'row', gap: 30 }}>
          {buildAnalysis(props.current, props.average, props.holiday)}
          {buildBreakdown(props.breakdown)}
        </View>
        {buildSignatureBlock()}
      </Page>
    </Document>
  );
};

const buildHeader = (user: IUser, month: Date) => {
  const employeeId = user.employeeId || '???';
  const displayName = `${user.lastName}, ${user.firstName}`;
  const date = month.toLocaleDateString('de-DE', {
    month: 'short',
    year: 'numeric'
  });
  return (
    <View style={{ paddingBottom: '10px' }}>
      <Text style={{ fontWeight: 'semibold', fontSize: '14pt' }}>
        Dienstaufstellung - {displayName} #{employeeId} {date}
      </Text>
      <Text>Kostenstelle: {'???'}</Text>
    </View>
  );
};

const buildCalendar = (days: DaySummary[]) => {
  const cols = {
    dayOfWeek: 10,
    date: 40,
    times: 60,
    abw: 40,
    td: 35,
    nd: 35,
    nb: 35,
    nlz: 35,
    soft: 45,
    space: 10
  };

  return (
    <View style={{ marginBottom: 30 }}>
      <View
        style={{
          flexDirection: 'row',
          fontSize: '9pt',
          textAlign: 'center',
          fontWeight: 'semibold'
        }}
      >
        <Text style={{ width: cols.dayOfWeek }}></Text>
        <Text style={{ width: cols.date }}>Datum</Text>
        <Text style={{ width: cols.times }}>Zeit</Text>
        <Text style={{ width: cols.abw }}>Abw</Text>
        <Text style={{ width: cols.td }}>TD</Text>
        <Text style={{ width: cols.nd }}>ND</Text>
        <Text style={{ width: cols.nb, color: '#aaa' }}>NB</Text>
        <Text style={{ width: cols.nlz }}>NLZ</Text>
        <Text style={{ width: cols.soft, color: '#aaa' }}>SO&FT</Text>
        <Text style={{ width: cols.space }}></Text>
        <Text style={{ textAlign: 'left', flex: 1 }}>Beschreibung</Text>
      </View>
      {days.map((day, j) => (
        <View
          key={j}
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            fontSize: '8pt',
            textAlign: 'center',
            borderBottom: '1px solid #ddd'
          }}
        >
          <Text style={{ width: cols.dayOfWeek }}>{day.dayOfWeek}</Text>
          <Text style={{ width: cols.date }}>{day.shortDate}</Text>
          <Text style={{ width: cols.times }}>{day.times}</Text>
          <Text style={{ width: cols.abw }}>
            {formatSeconds(day.breaks, 'hh:mm')}
          </Text>
          <Text style={{ textAlign: 'right', paddingRight: 2, width: cols.td }}>
            {formatSeconds(day.dayShift)}
          </Text>
          <Text style={{ textAlign: 'right', paddingRight: 2, width: cols.nd }}>
            {formatSeconds(day.nightShift)}
          </Text>
          <Text style={{ textAlign: 'right', paddingRight: 2, width: cols.nb }}>
            {formatSeconds(day.nightStandby)}
          </Text>
          <Text
            style={{ textAlign: 'right', paddingRight: 2, width: cols.nlz }}
          >
            {formatSeconds(day.nonWork)}
          </Text>
          <Text
            style={{
              textAlign: 'right',
              paddingRight: 4,
              width: cols.soft
            }}
          >
            {formatSeconds(day.sunPubHol)}
          </Text>
          <Text style={{ width: cols.space }}></Text>
          <View
            style={{
              flexDirection: 'row',
              flex: 1,
              columnGap: '2px',
              paddingLeft: '4px',
              textAlign: 'left',
              alignItems: 'center'
            }}
          >
            {day.tags.map((tag) => (
              <View
                style={{
                  backgroundColor: '#444444',
                  color: '#ffffff',
                  borderRadius: '4px',
                  fontSize: '8pt',
                  padding: '0 2px',
                  justifyContent: 'center',
                  fontWeight: 'semibold'
                }}
                key={tag}
              >
                <Text>{tag}</Text>
              </View>
            ))}
            <View style={{ flex: 1 }}>
              <Text style={{ paddingTop: 1, height: 14 }}>{day.notes}</Text>
            </View>
          </View>
        </View>
      ))}
      <View style={{ fontSize: '9pt', flexDirection: 'row' }}>
        <Text style={{ width: cols.dayOfWeek + cols.date }}>Summe</Text>
        <Text style={{ width: cols.times }}></Text>
        <Text style={{ width: cols.abw }}></Text>
        <Text style={{ width: cols.td, textAlign: 'right', paddingRight: 2 }}>
          {formatSeconds(
            days.reduce((acc, day) => acc + (day.dayShift || 0), 0),
            'decimal',
            false
          )}
        </Text>
        <Text style={{ width: cols.nd, textAlign: 'right', paddingRight: 2 }}>
          {formatSeconds(
            days.reduce((acc, day) => acc + (day.nightShift || 0), 0),
            'decimal',
            false
          )}
        </Text>
        <Text
          style={{
            width: cols.nb,
            textAlign: 'right',
            paddingRight: 2,
            color: '#aaa'
          }}
        >
          {formatSeconds(
            days.reduce((acc, day) => acc + (day.nightStandby || 0), 0),
            'decimal',
            false
          )}
        </Text>
        <Text style={{ width: cols.nlz, textAlign: 'right', paddingRight: 2 }}>
          {formatSeconds(
            days.reduce((acc, day) => acc + (day.nonWork || 0), 0),
            'decimal',
            false
          )}
        </Text>
        <Text
          style={{
            width: cols.soft,
            textAlign: 'right',
            paddingRight: 2,
            color: '#aaa'
          }}
        >
          {formatSeconds(
            days.reduce((acc, day) => acc + (day.sunPubHol || 0), 0),
            'decimal',
            false
          )}
        </Text>
        <Text style={{ flex: 1 }}></Text>
      </View>
    </View>
  );
};

const buildAnalysis = (
  current: MonthBaseSummary,
  average: MonthBaseSummary,
  holiday: HolidaySummary
) => {
  return (
    <View style={{ flex: 1, fontSize: '9pt', textAlign: 'center' }}>
      <View
        style={{
          flexDirection: 'row',
          fontWeight: 'semibold'
        }}
      >
        <Text style={{ width: 15 }}></Text>
        <Text style={{ flex: 1 }}>Arbeitszeit</Text>
        <Text style={{ flex: 1 }}>Soll-ST</Text>
        <Text style={{ flex: 1 }}>Ist-ST</Text>
        <Text style={{ flex: 1 }}>+/- ST</Text>
        <Text style={{ flex: 1 }}>+/- VM</Text>
        <Text style={{ flex: 1 }}>+/- ÜT</Text>
        <Text style={{ flex: 1 }}>Korr.LV</Text>
      </View>
      <View style={{ flexDirection: 'row', borderBottom: '1px solid #ddd' }}>
        <Text style={{ width: 15 }}></Text>
        <Text style={{ flex: 1 }}>{formatNum(current.contracted)}</Text>
        <Text style={{ flex: 1 }}>{formatNum(current.planned)}</Text>
        <Text style={{ flex: 1 }}>{formatNum(current.total)}</Text>
        <Text style={{ flex: 1 }}>
          {formatNum(current.total - current.planned)}
        </Text>
        <Text style={{ flex: 1 }}>{formatNum(current.previous)}</Text>
        <Text style={{ flex: 1 }}>
          {formatNum(current.previous + current.total - current.planned)}
        </Text>
        <Text style={{ flex: 1 }}>{formatNum(current.adj)}</Text>
      </View>
      <View style={{ flexDirection: 'row', borderBottom: '1px solid #ddd' }}>
        <Text style={{ width: 15 }}>&Oslash;</Text>
        <Text style={{ flex: 1 }}>{formatNum(average.contracted)}</Text>
        <Text style={{ flex: 1 }}>{formatNum(average.planned)}</Text>
        <Text style={{ flex: 1 }}>{formatNum(average.total)}</Text>
        <Text style={{ flex: 1 }}>
          {formatNum(average.total - average.planned)}
        </Text>
        <Text style={{ flex: 1 }}>{formatNum(average.previous)}</Text>
        <Text style={{ flex: 1 }}>
          {formatNum(average.previous + average.total - average.planned)}
        </Text>
        <Text style={{ flex: 1 }}>{formatNum(average.adj)}</Text>
      </View>

      <View
        style={{
          flexDirection: 'row',
          marginTop: 20,
          fontWeight: 'semibold',
          borderTop: '1px solid #ddd'
        }}
      >
        <Text style={{ flex: 1 }}>UT Übertrag</Text>
        <Text style={{ flex: 1 }}>UT Kons</Text>
        <Text style={{ flex: 1 }}>UT Rest</Text>
      </View>
      <View style={{ flexDirection: 'row', borderBottom: '1px solid #ddd' }}>
        <Text style={{ flex: 1 }}>{holiday.forYear - holiday.toStart}</Text>
        <Text style={{ flex: 1 }}>{holiday.toEnd - holiday.toStart}</Text>
        <Text style={{ flex: 1 }}>{holiday.forYear - holiday.toEnd}</Text>
      </View>
    </View>
  );
};

const buildBreakdown = (breakdown: BreakdownSummary) => {
  const cols = {
    label: 50,
    value: 80
  };
  return (
    <View style={{ fontSize: '9pt' }}>
      <View style={{ flexDirection: 'row', borderBottom: '1px solid #ddd' }}>
        <View style={{ width: cols.label }}>
          <Text>ND</Text>
        </View>
        <View style={{ width: cols.value }}>
          <Text>{formatNum(breakdown.nightshift)}</Text>
        </View>
      </View>
      <View style={{ flexDirection: 'row', borderBottom: '1px solid #ddd' }}>
        <View style={{ width: cols.label }}>
          <Text>NB</Text>
        </View>
        <View style={{ width: cols.value }}>
          <Text>{formatNum(breakdown.nightstandby)}</Text>
        </View>
      </View>
      <View style={{ flexDirection: 'row', borderBottom: '1px solid #ddd' }}>
        <View style={{ width: cols.label }}>
          <Text>SO FT</Text>
        </View>
        <View style={{ width: cols.value }}>
          <Text>{formatNum(breakdown.sundayPubHol)}</Text>
        </View>
      </View>
      <View style={{ flexDirection: 'row', borderBottom: '1px solid #ddd' }}>
        <View style={{ width: cols.label }}>
          <Text>KS</Text>
        </View>
        <View style={{ width: cols.value }}>
          <Text>
            {formatNum(breakdown.sickLeave)} ({breakdown.sickLeaveDays} Tag
            {breakdown.sickLeaveDays !== 1 && 'e'})
          </Text>
        </View>
      </View>
    </View>
  );
};

const buildSignatureBlock = () => {
  return (
    <View
      style={{
        position: 'absolute',
        bottom: 30,
        left: 24,
        right: 24,
        fontSize: '9pt'
      }}
    >
      <View style={{ flexDirection: 'row', gap: 40 }}>
        <View style={{ flex: 1, borderTop: '1px dotted #555' }}>
          <Text>Datum, Unterschrift Vorgesetzter</Text>
        </View>
        <View style={{ flex: 1, borderTop: '1px dotted #555' }}>
          <Text>Datum, Unterschrift Dienstnehmer</Text>
        </View>
      </View>
    </View>
  );
};
