import { ClientBackendContext } from '@kidsmanager/ui-api';
import {
  IEntry,
  IReleasedMemberPlan,
  IShiftSpec,
  emptyEntry
} from '@kidsmanager/util-models';
import { useContext, useEffect, useState } from 'react';
import {
  formatBreak,
  formatEnd,
  formatStart
} from '../../timesheet-common/timesheet-formatters';
import { Progress } from '@kidsmanager/ui-core';

export interface MonthDayDetailsProps {
  date?: Date;
  userId?: string;
}

const compileEntries = (
  date: Date,
  logged: IEntry[],
  specs: IShiftSpec[],
  plan: IReleasedMemberPlan
): IEntry[] => {
  const today = new Date();
  const endOfToday = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    23,
    59,
    59
  );
  if (logged.length === 0 && date > endOfToday) {
    const index = date.getDate() - 1;
    if (index >= plan.assigned.length) {
      return [];
    }
    const planned = plan.assigned[index].shifts.map((id) => {
      const spec = specs.find((x) => x.id === id);
      if (!spec) {
        return { ...emptyEntry, label: 'Unbekannt' };
      } else {
        const [hours, mins] = spec.start.split(':').map(Number);
        const durationSeconds = spec.hrs * 3600;
        const startSeconds =
          hours * 3600 + mins * 60 + date.getTimezoneOffset() * 60;
        return {
          ...emptyEntry,
          date: date.toLocaleISODate(),
          label: spec.name,
          startSeconds,
          durationSeconds
        };
      }
    });
    planned.sort((a, b) => a.startSeconds - b.startSeconds);
    return planned;
  } else {
    return [...logged];
  }
};

const DayInSeconds = 24 * 3600;

export const MonthDayDetails = (props: MonthDayDetailsProps) => {
  const client = useContext(ClientBackendContext);
  const [loading, setLoading] = useState(false);
  const [entries, setEntries] = useState<IEntry[]>([]);
  const [worked, setWorked] = useState(0);

  useEffect(() => {
    const date = props.date;
    if (!date) {
      return;
    }
    setLoading(true);
    const year = date?.getFullYear();
    const month = date?.getMonth() + 1;
    Promise.all([
      client.timesheet.week(date, props.userId),
      client.timesheet.contracted(year, month, props.userId),
      client.holiday.bookingsForYear(year, props.userId),
      client.holiday.publicHolidays(year),
      client.roster.shifts.for(year, month, props.userId),
      client.roster.plan.released(year, month, props.userId)
    ]).then(([week, contracted, holidays, publicHols, specs, plan]) => {
      const match = week.current.find(({ date }) =>
        props.date?.dateMatches(new Date(date))
      );
      if (!match) {
        setEntries([]);
        setWorked(0);
        return;
      }

      const date = new Date(match.date);
      const entries = compileEntries(date, match.entries, specs, plan);
      const booking = holidays.find((h) => date >= h.from && date <= h.to);

      if (booking && !publicHols.find((x) => x.dateMatches(date))) {
        entries.push({
          ...emptyEntry,
          label: 'Urlaub',
          date: date.toLocaleISODate(),
          startSeconds: 8 * 3600 + date.getTimezoneOffset() * 60,
          durationSeconds: contracted.hoursPerDay * 3600
        });
      }

      setEntries(entries);
      setWorked(
        entries
          .filter((x) => !x.infoOnly)
          .reduce(
            (sum, x) => sum + x.durationSeconds - x.breakDurationSeconds,
            0
          ) / 3600
      );
      setLoading(false);
    });
  }, [client, props.date, props.userId]);

  return !props.date ? undefined : (
    <div>
      <h2 className="flex items-center font-medium">
        <span className="flex-1">
          {props.date.toLocaleString('de-DE', {
            weekday: 'long',
            day: 'numeric',
            month: 'short'
          })}
        </span>
        <span className="text-sm text-black/50">{worked.toFixed(2)}h</span>
      </h2>
      <div className="min-h-3">
        {loading && <Progress mode="indeterminate" />}
      </div>
      {entries.map((entry, index) => (
        <div className="flex text-black/70" key={index}>
          <div className="basis-36">
            {formatStart(entry)} - {formatEnd(entry)}
            {entry.durationSeconds > DayInSeconds && (
              <span className="ml-1 text-sm">+1d</span>
            )}
          </div>
          <div className="flex-1">
            <div className="flex items-center">
              {entry.label}
              {entry.tag && (
                <span className="ml-1 rounded-md bg-orange-500 px-1 py-0.5 text-xs text-white">
                  {entry.tag}
                </span>
              )}
            </div>
            {entry.note && (
              <div className="pl-2 text-sm italic text-black/60">
                &quot;{entry.note}
              </div>
            )}
          </div>
          <div className="basis-32 text-right">{formatBreak(entry)}</div>
        </div>
      ))}
    </div>
  );
};
