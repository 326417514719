import { IAccessMap, IProfile } from '@kidsmanager/util-models';
import { IAuth, fetch } from './auth';
import { BackendCache } from './backend-cache';

const cache = new BackendCache();

export class ApiSettings {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {}

  async accessMap(): Promise<IAccessMap> {
    return cache.get<IAccessMap>('access-map', async () => {
      return (await this.fetch('api/settings/access-map')).json();
    });
  }

  async profile(): Promise<IProfile> {
    return cache.get('profile', async () => {
      return (await this.fetch('api/settings/profile')).json();
    });
  }

  async changePassword(
    oldPassword: string,
    newPassword: string
  ): Promise<boolean> {
    const response = await this.fetch('api/settings/password', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ oldPassword, newPassword })
    });
    return !response.ok;
  }

  async registerMfa(secret: string, code: string): Promise<boolean> {
    const response = await this.fetch('api/settings/mfa', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ secret, code })
    });
    return response.ok;
  }

  async unregisterMfa() {
    await this.fetch('api/settings/mfa', {
      method: 'DELETE'
    });
  }
}
