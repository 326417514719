import { IGroup } from '@kidsmanager/util-models';
import { IAuth, fetch } from '../auth';
import { BackendCache } from '../backend-cache';

const cache = new BackendCache();

export class ApiAdminGroups {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {}

  async all(): Promise<IGroup[]> {
    return cache.get('admin_group', async () => {
      const result = await this.fetch('api/admin/group');
      if (result.ok) {
        const groups = (await result.json()) as IGroup[];
        groups.sort((a, b) => a.name.localeCompare(b.name || ''));
        return groups || [];
      }
      return [];
    });
  }
}
