import { IUser, IUserGroup } from '@kidsmanager/util-models';
import { IAuth, fetch } from '../auth';
import { BackendCache } from '../backend-cache';

const cache = new BackendCache();

export class ApiUser {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {}

  async find(userId?: string): Promise<IUser> {
    return cache.get(`user-${userId}`, async () => {
      return (await this.fetch(`api/timesheet/users?id=${userId}`)).json();
    });
  }

  async byPrimaryGroup(): Promise<IUserGroup[]> {
    return cache.get('active-users', async () => {
      return (await this.fetch('api/timesheet/users')).json();
    });
  }
}
