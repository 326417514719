import React, { useContext } from 'react';
import { DialogDesktopContainer } from './dialog-desktop-container';
import { DialogMobileContainer } from './dialog-mobile-container';
import { MobileAwareLayout } from '../../mobile/mobile-aware-layout';

type SetContentFunc = (value: React.SetStateAction<React.ReactNode>) => void;

export interface IDialog {
  open(content: React.ReactNode): void;
  close(): void;
}

export class DialogControl implements IDialog {
  private _bind: SetContentFunc | undefined = undefined;

  open(content: React.ReactNode) {
    document.body.style.overflow = 'hidden';
    this._bind?.(content);
  }
  close(): void {
    document.body.style.overflow = 'auto';
    this._bind?.(undefined);
  }
  bind(x: SetContentFunc) {
    this._bind = x;
  }
}

export interface DialogProviderProps {
  children: React.ReactNode;
}

export const DialogContext = React.createContext(
  new DialogControl() as IDialog,
);

export const DialogProvider = (props: DialogProviderProps) => {
  const [content, setContent] = React.useState<React.ReactNode>();
  (useContext(DialogContext) as DialogControl).bind(setContent);

  const onClose = () => {
    document.body.style.overflow = 'auto';
    setContent(undefined);
  };

  return (
    <>
      {content && (
        <MobileAwareLayout
          desktop={
            <DialogDesktopContainer close={onClose.bind(this)}>
              {content}
            </DialogDesktopContainer>
          }
          mobile={
            <DialogMobileContainer close={onClose.bind(this)}>
              {content}
            </DialogMobileContainer>
          }
        />
      )}
      {props.children}
    </>
  );
};
