import {
  IDP,
  OtpExchangeResponse,
  TokenExchangeResponse
} from '@kidsmanager/util-models';
import { UserNotFoundException } from '@kidsmanager/util-common';
import { IAuth } from './auth';

type fetch = (url: string, init?: RequestInit) => Promise<Response>;

export class ApiOauth2 {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {}

  private async nativeFetch(url: string): Promise<Response> {
    //uses the native fetch function to avoid adding unnecessary headers
    //and also to avoid the Google Workspace signin popup being blocked
    // because it's not a direct consequence to the user's click.
    return fetch(url);
  }

  async googleWorkspaceUrl(
    role: 'USER' | 'ADMIN',
    tenant: string | null,
    origin: string,
    email?: string | null
  ): Promise<{ url: string }> {
    const params = new URLSearchParams({
      idp: 'google',
      origin,
      ...(role === 'ADMIN' && { type: 'elevate' }),
      ...(email && { email }),
      ...(tenant && { tenant })
    }).toString();
    return await (await this.nativeFetch(`/oauth2/authUrl?${params}`)).json();
  }

  async verify(
    code: string,
    origin: string,
    tenant: string | null
  ): Promise<TokenExchangeResponse | undefined> {
    const params = new URLSearchParams({
      idp: 'google',
      origin,
      code: code,
      ...(tenant && { tenant })
    }).toString();

    const response = await this.nativeFetch(`/oauth2/verify?${params}`);
    const responseJson = await response.json();
    if (response.ok) {
      return responseJson;
    } else if (response.status === 404) {
      throw new UserNotFoundException(
        responseJson.message,
        responseJson.email,
        responseJson.tenant,
        404
      );
    }
  }

  async link(): Promise<OtpExchangeResponse> {
    const url = '/oauth2/link';
    return await (await this.fetch(url, { method: 'POST' })).json();
  }

  async setEmailAddress(idp: IDP, email: string) {
    const url = '/oauth2/email';
    return await (
      await this.fetch(url, {
        method: 'POST',
        body: JSON.stringify({ email, idp })
      })
    ).json();
  }
}
