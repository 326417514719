import {
  IReleasedMemberPlan,
  IShift,
  IShiftSpec
} from '@kidsmanager/util-models';
import { Info } from '@kidsmanager/ui-core';
import { useEffect, useMemo, useState } from 'react';
import { PlannedSwitcher } from './planned-switcher';

export interface PlannedShiftProps {
  date?: string;
  planned?: IReleasedMemberPlan;
  specs?: IShiftSpec[];
  selected?: IShift;
  children: React.ReactNode;
  onChange?: (id: string | undefined) => void;
}

export const PlannedShift = (props: PlannedShiftProps) => {
  const [selected, setSelected] = useState<string>();

  const assigned = useMemo(() => {
    const day = Number(props.date?.split('-')[2]);
    return props.planned?.assigned[day - 1]?.shifts;
  }, [props.planned, props.date]);

  const options = useMemo(() => {
    if (!assigned) {
      return;
    }
    const filtered = assigned.reduce((prev, id) => {
      const spec = props.specs?.find((x) => x.id === id);
      if (spec) {
        prev.push(spec);
      }
      return prev;
    }, [] as IShiftSpec[]);
    filtered.sort((a, b) => (a.start > b.start ? 1 : -1));
    return filtered.map((x) => ({
      id: x.id,
      label: x.name
    }));
  }, [props.specs, assigned]);

  useEffect(() => {
    setSelected(options?.find((x) => x.label === props.selected?.name)?.id);
  }, [props.selected, options]);

  const onChange = (id?: string) => {
    setSelected(id);
    props.onChange?.(id);
  };

  const title = () => {
    return options?.find((x) => x.id === selected)?.label;
  };

  return assigned?.length ? (
    <div className="mb-6">
      <div className="absolute inset-x-0 top-0 -m-2 flex justify-center bg-neutral-100 p-2 text-center text-black/70 sm:py-3">
        <span className="mr-1 text-sm">Geplanter &quot;{title()}&quot;</span>
        <Info position="center">geplant von {props.planned?.releasedBy}</Info>
        <div className="absolute bottom-0 right-2 top-0 flex items-center">
          <PlannedSwitcher
            options={options}
            onChange={onChange.bind(this)}
            selected={selected}
          />
        </div>
      </div>
    </div>
  ) : (
    props.children
  );
};
